import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Button } from '@mui/material';
import SelectedEmail from './SelectedEmail';
import { primaryColor } from '../../../../styled-components/StyleSettings';
import { SecondaryButton, PrimaryButton } from '../../../../styled-components/Buttons';
import { useNavigate } from 'react-router-dom';
import { useSheetsExtension } from '../SheetsExtensionContext';

const EmailPreview = ({ emails, rowCount, columns, rows, userEmail, sheetsUrl, actionConfig, accountId, sheetsUrlBasic, gidBasic }) => {
    const {csrfTokenGlobal, saveAsTemplate, trackEmails, trackLinks, scheduledDate, jobName, stopOnFailedItems, attachment, emailData} = useSheetsExtension()
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate()
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
          const cookies = document.cookie.split(";");
    
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
    
              break;
            }
          }
        }
        return cookieValue;
      }
      const csrftoken = getCookie("csrftoken");
     
    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleSendToRecipients = async () => {
      // Função para converter classes ql-* em estilos inline
      const convertQuillClassesToInlineStyles = (htmlContent) => {
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = htmlContent;
  
          // Substituir classes específicas por estilos inline
          tempDiv.querySelectorAll('.ql-align-center').forEach((el) => {
              el.style.textAlign = 'center';
              el.classList.remove('ql-align-center');
          });
  
          tempDiv.querySelectorAll('[class*="ql-size-"]').forEach((el) => {
              const sizeClass = [...el.classList].find((cls) => cls.startsWith('ql-size-'));
              if (sizeClass) {
                  const sizeMap = {
                      'ql-size-small': '0.75em',
                      'ql-size-large': '1.5em',
                      'ql-size-huge': '2.5em',
                  };
                  el.style.fontSize = sizeMap[sizeClass] || 'inherit';
                  el.classList.remove(sizeClass);
              }
          });
  
          return tempDiv.innerHTML;
      };
  
      // Extrair spreadsheet_id e sheet_id da URL do Google Sheets
      const spreadsheetIdMatch = sheetsUrl.match(/spreadsheets\/d\/([a-zA-Z0-9-_]+)/);
      const sheetIdMatch = sheetsUrl.match(/gid=([0-9]+)/);
  
      const spreadsheet_id = spreadsheetIdMatch ? spreadsheetIdMatch[1] : null;
      const sheet_id = sheetIdMatch ? sheetIdMatch[1] : null;
  
      if (!spreadsheet_id || !sheet_id) {
          console.error('Error: Invalid Google Sheets URL format');
          return;
      }
  
      // Converter body_html de actionConfig para estilos inline
      if (actionConfig && actionConfig.body_html) {
          actionConfig.body_html = convertQuillClassesToInlineStyles(actionConfig.body_html);
      }
  
  
      const payload = {
          data_source_type: 'google.gsheets',
          user_email: userEmail,
          data_source_config: { spreadsheet_id, sheet_id },
          data_source_columns: columns,
          action_type: 'gmail.send-email',
          action_config: actionConfig,
          job_settings: {
              save_template: saveAsTemplate,
              stop_on_failed_items: stopOnFailedItems,
              detailed_warm_up: false,
              warm_up_setting: 0,
              track_emails: trackEmails,
              track_links: trackLinks
          },
          action_account_id: accountId,
          job_item_rows: rows,
          scheduled_date_time: scheduledDate,
          campaign_name: jobName,
      };
  
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      if (attachment) {
          formData.append("attachment", attachment);
      }
  
      // Enviar requisição para a API
      try {
          const response = await fetch('https://postsheetapp.com/api/jobs/create-job-job-items-extension', {
              method: 'POST',
              credentials: 'include',
              headers: {
                  'X-CSRFToken': csrfTokenGlobal
              },
              body: formData
          });
  
          if (response.ok) {
              // Sucesso na requisição
          } else {
              console.error('Failed to create job and job items');
          }
          const result = await response.json();
          const uid = result.job.uid;
  
          if (!scheduledDate) {

              navigate(`/extension/details/${uid}`, {
                  state: {
                      totalItems: rows.length,
                      job: result.job,
                      enableRealTimeParam: true,
                      gid: gidBasic,
                      sheetsUrl: sheetsUrlBasic,
                      userEmail: userEmail
                  }
              });
              sendEmails(result.processed_emails, result.job.id, result.django_user_id);
          } else {
              navigate(`/extension/details/${uid}`, {
                  state: {
                      totalItems: rows.length,
                      job: result.job,
                      enableRealTimeParam: false,
                      gid: gidBasic,
                      sheetsUrl: sheetsUrlBasic,
                      userEmail: userEmail
                  }
              });
          }
      } catch (error) {
          console.error('Error sending request:', error);
      }
  };
    const sendEmails = async (emails, job_id, django_user_id) => {
        const batchSize = 5;
        let allBatchesSent = true;
    
        for (let i = 0; i < emails.length; i += batchSize) {
            const emailBatch = emails.slice(i, i + batchSize);
            const isFirstBatch = i === 0;
            const isLastBatch = (i + batchSize) >= emails.length;
            const formData = new FormData();
            formData.append('data', JSON.stringify({
                emails: emailBatch,
                job_id: job_id,
                is_first_batch: isFirstBatch,
                is_last_batch: isLastBatch,
                django_user_id: django_user_id
            }));
            // if (attachment) {
            //     formData.append('attachment', attachment);
            // }
    
            try {
                const response = await fetch('https://postsheetapp.com/api/emails/send-extension', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'X-CSRFToken': csrfTokenGlobal                 
                    },
                    body: formData,
                });
    
                if (!response.ok) {
                    throw new Error('Failed to send emails');
                }
    
                const result = await response.json();
    
                if (result.error) {
                    throw new Error(result.error);
                }
                await new Promise(resolve => setTimeout(resolve, 500));
            } catch (error) {
                console.error('Error sending emails:', error);
                allBatchesSent = false;
                break;  // Exit the loop on error
            }
        }
    
      
    };
    
    const handleShowDetailedPreviewModal = () => {
        if (!selectedEmail) {
          console.error('Error: selectedEmail is null or undefined');
          return;
        }
      
        const htmlString = createDetailedPreviewHTML(selectedEmail);
      
        // Send message to parent window with the HTML
        window.parent.postMessage(
          {
            action: 'send_failed_modal',
            html: htmlString
          },
          '*'
        );
      };

    const emailTabs = Array.from({ length: Math.min(emails.length, 3) }, (_, i) => `Preview ${i + 1}`);
    const selectedEmail = emails[selectedTab];
    function createDetailedPreviewHTML(selectedEmail) {
        // Adapted styles from previous scenario
      
        const paperStyle = `
          -webkit-tap-highlight-color: transparent;
          -webkit-text-size-adjust: 100%;
          font-feature-settings: normal;
          font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
          font-size: 15px;
          font-variation-settings: normal;
          tab-size: 4;
          border: 0 solid #d8d5d2;
          box-sizing: border-box;
          background-color: rgb(255, 255, 255);
          color: rgba(0, 0, 0, 0.87);
          box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                      rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                      rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
          position: relative;
          transition: box-shadow 300ms cubic-bezier(0.4,0,0.2,1);
          border-radius: 4px;
          padding: 32px;
          margin: 16px;
        `;
      
        const titleStyle = `
          -webkit-tap-highlight-color: transparent;
          -webkit-text-size-adjust: 100%;
          font-feature-settings: normal;
          font-variation-settings: normal;
          tab-size: 4;
          font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.75rem;
          margin-top: 0.25rem;
          margin: 0;
        `;
      
        const containerStyle = `
          -webkit-tap-highlight-color: transparent;
          -webkit-text-size-adjust: 100%;
          font-feature-settings: normal;
          font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
          font-size: 15px;
          font-variation-settings: normal;
          tab-size: 4;
          border: 0 solid #d8d5d2;
          box-sizing: border-box;
          margin-top: 16px;
        `;
      
        const textStyle = `
          -webkit-tap-highlight-color: transparent;
          -webkit-text-size-adjust: 100%;
          font-feature-settings: normal;
          font-family: inherit;
          font-size: 1rem;
          line-height: 1.5rem;
          margin: 0.5rem 0;
          color: rgba(0,0,0,0.87);
        `;
      
        // Extract details from selectedEmail
        const fromText = `From: ${selectedEmail.from_name} &lt;${selectedEmail.from_address}&gt;`;
        const toText = `To: ${selectedEmail.to_name} &lt;${selectedEmail.to_address}&gt;`;
        const subjectText = `Subject: ${selectedEmail.subject || ''}`;
        const bodyHtml = selectedEmail.body_html || '';
      
        return `
          <div style="${paperStyle}">
            <h3 style="${titleStyle}">Detailed Preview</h3>
            <div style="${containerStyle}">
              <p style="${textStyle}"><b>${fromText}</b></p>
              <p style="${textStyle}"><b>${toText}</b></p>
              <p style="${textStyle}"><b>${subjectText}</b></p>
              <div style="margin-top: 1rem; ${textStyle}">
                ${bodyHtml}
              </div>
            </div>
          </div>
        `;
      }
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleChangeTab}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: primaryColor,
                        }
                    }}
                    sx={{
                        '.MuiTab-root': { color: 'rgba(0, 0, 0, 0.6)' },
                        '.Mui-selected': { color: 'black !important' }
                    }}
                >
                    {emailTabs.map((tab, index) => (
                        <Tab key={index} label={tab} />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{ p: 2, overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                <SelectedEmail selectedEmail={selectedEmail} />
                <PrimaryButton onClick={handleSendToRecipients} sx={{ mt: 2 }}>Send to {rowCount} recipients</PrimaryButton>
                <SecondaryButton variant="contained" color="primary" onClick={handleShowDetailedPreviewModal} sx={{ mt: 1 }}>
  Detailed Preview
</SecondaryButton>
            </Box>
        </>
    );
};

export default EmailPreview;
