import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Paper, Box, Select, MenuItem, FormControl, InputLabel, CircularProgress, Collapse, IconButton, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import './styles.css';  // Import the CSS file
import { H2, H3 } from '../../../../../../styled-components/Typography';
import { useNavigate } from 'react-router-dom';

const JobStats = ({ jobId }) => {
    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [interval, setInterval] = useState('always'); // Default to 'always' to load all data
    const [chartsVisible, setChartsVisible] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate()
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    
    const csrftoken = getCookie("csrftoken");
    const recordError = async (errorMessage, apiUrl, requestBody) => {
        try {
          const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
          await fetch(errorRecordUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrftoken, 
            },
            body: JSON.stringify({
              error_message: errorMessage,
              api_url: apiUrl,
              request: requestBody,
            }),
          });
        } catch (err) {
          console.error('Failed to record the error:', err);
        }
      };
    useEffect(() => {
      
        fetch(`https://postsheetapp.com/api/job_stats/${jobId}`)
            .then(response => response.json())
            .then(data => {
                  
                setStats(data);
         
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching job stats:', error);
                recordError(error.message, `https://postsheetapp.com/api/job_stats/${jobId}`, null);
                navigate('/app/error', { state: { errorMessage: error.message } });
                setLoading(false);
            });
    }, [jobId]);

    const columns = [
        { field: 'redirect_url', headerName: 'Link URL', width: 250 },
        { field: 'visit_date', headerName: 'Clicked On', width: 150 },
        {field:'receiver', headerName: 'Receiver', width: 400}
    ];

    const handleIntervalChange = (event) => {
        setInterval(event.target.value);
        setStartDate(null);
        setEndDate(null);
    };

    const toggleChartsVisibility = () => {
        setChartsVisible(!chartsVisible);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        if (date && endDate && date > endDate) {
            setEndDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        if (date && startDate && date < startDate) {
            setStartDate(null);
        }
    };

    const formatDate = (date) => {
        return dayjs(date).format('MM-DD-YYYY');
    };

    const filterDataByInterval = (data, interval) => {

        const now = dayjs();

        let startDate = now;

        let endDate = now;

        switch (interval) {
            case 'day':

                startDate = now.subtract(1, 'day');

                endDate = now;
                break;
            case 'week':

                startDate = now.subtract(1, 'week');

                endDate = now;
                break;
            case 'month':

                startDate = now.subtract(1, 'month');

                endDate = now;
                break;
            case 'year':

                startDate = now.subtract(1, 'year');

                endDate = now;
                break;
            default:
                return data;
        }
 
        return data.filter(item => {
            const itemDate = dayjs(item.first_open_date || item.visit_date);
            return itemDate.isAfter(startDate) && itemDate.isBefore(endDate);
        });
    };

    const filterDataByDateRange = (data, startDate, endDate) => {

        if (!startDate || !endDate) {
            return data;
        }
    
        let start = dayjs(startDate).startOf('day');
        let end = dayjs(endDate).endOf('day');

        if (start.isSame(end, 'day')) {

            start = start.subtract(1, 'day');

        }
    
        return data.filter(item => {
            const itemDate = dayjs(item.first_open_date || item.visit_date);
            return itemDate.isAfter(start) && itemDate.isBefore(end);
        });
    };

    const getFilteredData = (dataKey) => {

        let data = stats[dataKey]?.map((item, index) => ({
            id: index,
            ...item,
            first_open_date: formatDate(item.first_open_date),
            visit_date: item.visit_date ? formatDate(item.visit_date) : null
        })) || [];



        if (startDate && endDate) {

            data = filterDataByDateRange(data, startDate, endDate);

        } else {
            
            data = filterDataByInterval(data, interval);

        }



        return data;
    };

    const openData = getFilteredData('emails_opened');
    const clickData = getFilteredData('links_clicked');

    const clickDataGroupedByDate = clickData
        .filter(item => item.visit_date)
        .reduce((acc, item) => {
            const date = item.visit_date.split(' ')[0];
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});



    const clickDataForChart = Object.keys(clickDataGroupedByDate).map(date => ({
        date,
        count: clickDataGroupedByDate[date]
    }));



    if (loading) {
      return (
        <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        </Paper>
      );
    }

    return (
        <Paper elevation={3} sx={{ p: 4, m: 2 }}>
            <H2 style= {{marginBottom: '1rem'}}>Job Stats</H2>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <H3 variant="h5">Total Emails Sent</H3>
                                    <Typography variant="h4">{stats.total_emails}</Typography>
                                    <Typography variant="h6">{Math.ceil(stats.succeeded_emails / stats.total_emails * 100).toFixed(2)}% Delivered</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <H3 variant="h5">Succesful Emails</H3>
                                    <Typography variant="h4">{stats.succeeded_emails}</Typography>
                                    <Typography variant="h6">{Math.ceil(stats.succeeded_emails / stats.total_emails * 100).toFixed(2)}%</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <H3 variant="h5">Failed Emails</H3>
                                    <Typography variant="h4">{stats.failed_emails}</Typography>
                                    <Typography variant="h6">{Math.ceil(stats.failed_emails / stats.total_emails * 100).toFixed(2)}%</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                            <Grid item xs={4}>
          <H3>Open Rate</H3>
          {stats.tracking.track_emails ? (
            <>
              <Typography variant="h4">{stats.open_rate.toFixed(2)}%</Typography>
              <Typography variant="h6">{Math.ceil(stats.open_rate * stats.total_emails / 100)} of {stats.total_emails} opened</Typography>
            </>
          ) : (
            <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
              <Typography sx={{ color: '#8a3c09' }}>
                Tracking Emails are not enabled for this job
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={4}>
          <H3>Click Rate</H3>
          {stats.tracking.track_links ? (
            <>
              <Typography variant="h4">{stats.click_rate.toFixed(2)}%</Typography>
              <Typography variant="h6">{Math.ceil(stats.click_rate * stats.total_emails / 100)} of {stats.total_emails} clicked</Typography>
            </>
          ) : (
            <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
              <Typography sx={{ color: '#8a3c09' }}>
                Tracking Links are not enabled for this job
              </Typography>
            </Box>
          )}
        </Grid>
                                <Grid item xs={4}>
                                    <H3>Unsubscribed Emails</H3>
                                    <Typography variant="h4">{(stats.unsubscribed_emails / stats.total_emails * 100).toFixed(2)}%</Typography>
                                    <Typography variant="h6">{stats.unsubscribed_emails} of {stats.total_emails} unsubscribed.</Typography>
                                </Grid>
                               
                               
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12}>
  <Box display="flex" alignItems="center">
    <Typography variant="h6" sx={{ mr: 2 }}>Charts</Typography>
  </Box>
  <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
    <FormControl sx={{ minWidth: 120, mr: 4 }} disabled={startDate && endDate}>
      <InputLabel>Interval</InputLabel>
      <Select
        value={interval}
        onChange={handleIntervalChange}
        label="Interval"
        size="small"
      >
        <MenuItem value="always">Always</MenuItem>
        <MenuItem value="day">Day</MenuItem>
        <MenuItem value="week">Week</MenuItem>
        <MenuItem value="month">Month</MenuItem>
        <MenuItem value="year">Year</MenuItem>
      </Select>
    </FormControl>
    <Grid container spacing={0.5} alignItems="center">
      <Grid item xs={6}>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          dateFormat="MMMM d, yyyy"
          customInput={<TextField label="Start Date" fullWidth variant="outlined" size="small" />}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          dateFormat="MMMM d, yyyy"
          customInput={<TextField label="End Date" fullWidth variant="outlined" size="small" />}
        />
      </Grid>
    </Grid>
  </Box>
  <IconButton onClick={toggleChartsVisibility} sx={{ ml: 2 }}>
    {chartsVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  </IconButton>
  <Collapse in={chartsVisible}>
    <Grid container spacing={3}>

      {/* Emails Opened Over Time */}
      <Grid item xs={12}>
        <Typography variant="h6">Emails Opened Over Time</Typography>
        {stats.tracking.track_emails ? (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={openData}>
              <XAxis dataKey="first_open_date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="open_count" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
            <Typography sx={{ color: '#8a3c09' }}>
              Tracking Emails are not enabled for this job
            </Typography>
          </Box>
        )}
      </Grid>

      {/* Links Clicked Over Time */}
      <Grid item xs={12}>
        <Typography variant="h6">Links Clicked Over Time</Typography>
        {stats.tracking.track_links ? (
            <>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={clickDataForChart}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="count" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
               <Grid item xs={12} className="data-grid-container">
               <DataGrid 
                 rows={clickData} 
                 columns={columns} 
                 autoHeight 
                 initialState={{
                   pagination: {
                     paginationModel: {
                       pageSize: 5,
                     },
                   },
                 }} 
                 pageSizeOptions={[5]} 
               />
             </Grid>
             </>
        ) : (
          <Box sx={{ mt: 2, p: 2, backgroundColor: '#fde9e2', borderRadius: '4px' }}>
            <Typography sx={{ color: '#8a3c09' }}>
              Tracking Links are not enabled for this job
            </Typography>
          </Box>
        )}
      </Grid>


 
      
    </Grid>
  </Collapse>
</Grid>

                
            </Grid>
        </Paper>
    );
};

export default JobStats;
