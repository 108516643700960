import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; 
const ProfileContext = createContext();


export const ProfileFiles = () => useContext(ProfileContext);


export const ProfileProvider = ({ children }) => {
  const navigate = useNavigate()
 
  const { id, uid } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [managedProfileData, setManagedProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  // Função para realizar a chamada à API
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const fetchProfileData = async () => {
 
    try {
      const url = 'https://postsheetapp.com/api/profile'
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
   
      setProfileData(data);
     
    } catch (error) {
      setError(error);
      recordError(error.message, 'https://postsheetapp.com/api/profile', { userId: id });
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  };

  const fetchManagedProfileData = async () => {

    try {
      const url = 'https://postsheetapp.com/api/managed-profile'
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
   
      setManagedProfileData(data);
      return data;
    } catch (error) {
      setError(error);
      recordError(error.message, 'https://postsheetapp.com/api/managed-profile', null);
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  };
  // useEffect para chamar fetchProfileData quando o componente é montado
  useEffect(() => {
   
    fetchProfileData();
  }, []); // O array vazio garante que o efeito seja executado apenas uma vez após a montagem do componente

    return (
      <ProfileContext.Provider value={{ setManagedProfileData, fetchManagedProfileData, managedProfileData, profileData, loading, error}}>
        {children}
      </ProfileContext.Provider>
    );
  };
  
  export const useProfile = () => useContext(ProfileContext);