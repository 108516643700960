import React, { createContext, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const ThreadsContext = createContext(null);

export const useThreadsContext = () => useContext(ThreadsContext);

export const ThreadsProvider = ({ children }) => {
  const navigate = useNavigate();
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const fetchThreads = async (jobId) => {
    try {
      const response = await fetch('https://postsheetapp.com/api/job/threads/get', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ job_id: jobId }),
      });
      const data = await response.json();
      if (response.ok) {
      
        return data;
      } else {
        console.error('Failed to fetch threads:', data.error);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
      recordError(error.message, 'https://postsheetapp.com/api/job/threads/get', { job_id: jobId });

      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };
      // useEffect to call both fetch functions on component mount
      

  return (
    <ThreadsContext.Provider value={{  fetchThreads }}>
      {children}
    </ThreadsContext.Provider>
  );
};
