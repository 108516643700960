import React, { useEffect, useState } from 'react';
import { Paper, Card, CardActionArea, CardMedia, Box } from '@mui/material';
import { H3, LinkSimple, DataSourceTitle }from '../../../../../styled-components/Typography';
import { useJobDetailsExtension } from '../../DetailsExtensionContext';
import { ImArrowRight2 } from "react-icons/im";

const ActionDetails = ({ dataSourceAccountId, actionAccountId, dataSourceTypeKey }) => {
    const [dataSourceAccount, setDataSourceAccount] = useState({});
    const [actionAccount, setActionAccount] = useState({});
    const [actionType, setActionType] = useState({});
    const [dataSourceType, setDataSourceType] = useState({});
    const { getJobAccountsDetails, cardStyle, imgStyle } = useJobDetailsExtension();

    useEffect(() => {

        const fetchData = async () => {
            const data = await getJobAccountsDetails(dataSourceAccountId, actionAccountId);

            setActionAccount(data.action_account);
            setDataSourceAccount(data.data_source_account);
            setActionType(data.action_type);
            setDataSourceType(data.data_source_type);
        };
      
        fetchData();
    }, [dataSourceAccountId, actionAccountId, getJobAccountsDetails]);

    // Capitalize the first letter of dataSourceTypeKey
    const capitalizedDataSourceTypeKey = dataSourceTypeKey.charAt(0).toUpperCase() + dataSourceTypeKey.slice(1);

    const dataSourceImage = dataSourceType?.config_view_name 
        ? `../../static/assets/images/data-sources/${dataSourceType.config_view_name}.svg`
        : `../../static/assets/images/data-sources/${dataSourceTypeKey}.svg`;

    const dataSourceTitle = dataSourceType?.title || capitalizedDataSourceTypeKey;

    return (
        <Paper elevation={3} sx={{ p: 2, m: 1, width: '220px' }}>
          <H3>Details</H3>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'flex-start', 
              gap: 2,
              mt: 2
            }}
          >
    
            <Card 
              sx={{ 
                width: 200, 
                height: 100, 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center' 
              }}
            >
              <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <CardMedia
                  component="img"
                  alt={actionType.title}
                  sx={{ maxHeight: '60%', maxWidth: '60%', objectFit: 'contain', m: 'auto' }}
                  image={`../../static/assets/images/accounts/${actionType.requires_account_type}.svg`}
                />
                <DataSourceTitle sx={{ fontSize: '0.9rem', mt: 1, textAlign: 'center' }}>
                  {actionType.title}
                </DataSourceTitle>
                
              </CardActionArea>
            </Card>
          </Box>
        </Paper>
      );
    };
    
    export default ActionDetails;
