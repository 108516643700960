import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useAuth } from "./AuthContext";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

const NewCampaignContext = createContext();

// Hook customizado para facilitar o uso do contexto
export const useNewCampaign = () => useContext(NewCampaignContext);

// Provedor do contexto
export const NewCampaignProvider = ({ children }) => {

  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [smsBody, setSmsBody] = useState("")
  const [loadingBeforeRunJob, setLoadingBeforeRunJob] = useState(false) 
  const [subscriptionData, setSubscriptionData] = useState({});
  const [userDataSourceChoices, setUserDataSourceChoices] = useState([]);
  const [highlightNextButton, setHighlightNextButton] = useState(false)
  const [isTemplateInitialized, setIsTemplateInitialized] = useState(false);
  const [animate, setAnimate] = useState(true);
  const [availableDataSourceChoices, setAvailableDataSourceChoices] = useState([]);
  const [userActionTypes, setUserActionTypes] = useState([]);
  const [jobItemRows, setJobItemRows] = useState([])
  const [templateItemRows, setTemplateItemRows] = useState([])
  const [availableActionTypes, setAvailableActionTypes] = useState([]);
  const [actionType, setActionType] = useState ("")
  const [airtableApiKey, setAirtableApiKey] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [actionAccountId, setActionAccountId] = useState("")
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [isToNextStep, setIsToNextStep] = useState(false)
  const [dataSourceType, setDataSourceType] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [actionConfig, setActionConfig] = useState({});
  const [jobActionConfig, setJobActionConfig] = useState({});
  const [dataSourceColumns, setDataSourceColumns] = useState([]);
  const [dataSourceConfig, setDataSourceConfig] = useState({});
  const [googleSheetUrl, setGoogleSheetUrl] = useState("");
  const [airtableBases, setAirtableBases] = useState([]);
  const [previewEmails, setPreviewEmails] = useState([]);
  const [selectedBaseId, setSelectedBaseId] = useState('');
  const [selectedTableId, setSelectedTableId] = useState('');
  const [bccList, setBccList] = useState([]);
  const [scheduledDate, setScheduledDate] = useState(null)
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [trackEmails, setTrackEmails] = useState(false);
  const [trackLinks, setTrackLinks] = useState(false);
  const [stopOnFailedItems, setStopOnFailedItems] = useState(false);
  const [warmUpSetting, setWarmUpSetting] = useState(0);
  const [detailedInboxSpamWarmUp, setDetailedInboxSpamWarmUp] = useState(false);
  const [dataSourceAccountId, setDataSourceAccountId] = useState("")
  const [loading, setLoading] = useState(false);
  const [jobName, setJobName] = useState("")
  const [attachment, setAttachment] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const editorRef = useRef(null);
  const activeInputRef = useRef(null);
  const [emailData, setEmailDataInternal] = useState({
    fromAddress: user.email,
    fromName: "",
    toAddress: "",
    toName: "",
    subject: "",
    emailTemplate: "",
    bcc:"",
    unsubMessage: "If you'd like me to stop sending you emails, please",
    unsubLink: "click here"
  });

  const [twilioData, setTwilioDataInternal] = useState({
    fromNumber: "",
    toNumber: "",
    smsTemplate: "",
    unsubMessage: "If you'd like me to stop sending you sms, please",
    unsubLink: "click here"
  });

  const [templateData, setTemplateDataInternal] = useState({
    fromAddress: user.email,
    fromName: "",
    toAddress: "",
    toName: "",
    subject: "",
    emailTemplate: "",
    bcc:"",
    unsubMessage: "",
    unsubLink: ""
  });
  const clearTemplateData = () => {
   
    setTemplateDataInternal({
      fromAddress: user.email,
      fromName: "",
      toAddress: "",
      toName: "",
      subject: "",
      emailTemplate: "",
      bcc: "",
      unsubMessage: "",
      unsubLink: ""
    });
  };
  

  const setEmailData = (name, value) => {
    setEmailDataInternal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const setTwilioData = (name, value) => {
    setTwilioDataInternal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [twilioTemplateData, setTwilioTemplateDataInternal] = useState({
    fromNumber: "",
    toNumber: "",
    smsTemplate: "",
    unsubMessage: "If you'd like me to stop sending you sms, please",
    unsubLink: "click here"
  });
  const clearTwilioTemplateData = () => {
  
    setTwilioTemplateDataInternal({
      fromNumber: "",
    toNumber: "",
    smsTemplate: "",
    unsubMessage: "If you'd like me to stop sending you sms, please",
    unsubLink: "click here"
    });
  };
  const setTemplateData = (name, value) => {
    setTemplateDataInternal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const setTwilioTemplateData = (name, value) => {
    setTwilioTemplateDataInternal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleInsertPlaceholder = (placeholder) => {
    const active = activeInputRef.current;
    const placeholderText = `{{${placeholder}}}`;

    if (active === editorRef.current) {
      // Inserção no CKEditor
      const model = active.model;
      const selection = model.document.selection;
      model.change((writer) => {
        const insertPosition = selection.getFirstPosition();
        writer.insertText(placeholderText, insertPosition);
      });
      active.editing.view.focus();
    } else if (active) {
     
      // Inserção em campos de input
      const start = active.selectionStart;
      const end = active.selectionEnd;
      const text = active.value;
      const newText =
        text.substring(0, start) + placeholderText + text.substring(end);
      
      setEmailData(active.name, newText);

      setTimeout(() => {
        active.focus();
        active.selectionStart = active.selectionEnd =
          start + placeholderText.length;
      }, 0);
    }
  };
  const handleInsertTemplatePlaceholder = (placeholder) => {
    const active = activeInputRef.current;
    const placeholderText = `{{${placeholder}}}`;
  
    if (active === editorRef.current) {
      // Inserção no CKEditor
      const model = active.model;
      const selection = model.document.selection;
      model.change((writer) => {
        const insertPosition = selection.getFirstPosition();
        writer.insertText(placeholderText, insertPosition);
      });
      active.editing.view.focus();
    } else if (active) {
      // Inserção em campos de input
      const start = active.selectionStart;
      const end = active.selectionEnd;
      const text = active.value;
      const newText =
        text.substring(0, start) + placeholderText + text.substring(end);
      
      setTemplateData(active.name, newText);
  
      setTimeout(() => {
        active.focus();
        active.selectionStart = active.selectionEnd =
          start + placeholderText.length;
      }, 0);
    }
  };
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const handlePreview = async () => {
    let requestData;
   
    
    if (actionType === 'gmail.send-email') {
      const bodyHtml = editorRef.current.getData(); // Captura o conteúdo do CKEditor
      setEmailData('emailTemplate', bodyHtml)
      // Forma o objeto JSON com os dados dos inputs e do editor
      const emailPayload = {
        subject: emailData.subject,
        to_name: emailData.toName,
        body_html: bodyHtml,
        from_name: emailData.fromName,
        to_address: emailData.toAddress,
        from_address: emailData.fromAddress,
        bcc: emailData.bcc,
        unsub_message: emailData.unsubMessage,
        unsub_link: emailData.unsubLink,
      };
  
      // Objeto de dados para enviar
      requestData = {
        email_payload: emailPayload,
        columns: actionConfig.columns,
        rows: actionConfig.rows,
      };
  
      updateActionConfig({ action_config: requestData });
      setJobActionConfig(emailPayload);
    } else  {
    
      const smsBody = editorRef.current.getData();
    

    
      setTwilioData("smsTemplate", smsBody)
      setEmailData("emailTemplate", smsBody)
      const smsPayload = {
        to_number: twilioTemplateData.toNumber,
        from_number: twilioTemplateData.fromNumber,
        body: smsBody,
      };
  
      // Objeto de dados para enviar
      requestData = {
        sms_payload: smsPayload,
        columns: actionConfig.columns,
        rows: actionConfig.rows,
      };
  
      updateActionConfig({ action_config: requestData });
      setJobActionConfig(smsPayload);
    }
  
    setDataSourceColumns(actionConfig.columns);
    setJobItemRows(actionConfig.rows);
  
    try {
    
      const response = await fetch("https://postsheetapp.com/api/emails/preview", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
  
      const result = await response.json();
      setPreviewEmails(result);
  
  
      // Processar resposta ou atualizar o estado conforme necessário
    } catch (error) {
      console.error("Failed to fetch:", error);
      recordError(error.message, 'https://postsheetapp.com/api/emails/preview', requestData);
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };
  
  const updateActionConfig = (newData) => {
   
    setActionConfig((prevConfig) => ({ ...prevConfig, ...newData }));
  };

  const updateDataSourceConfig = (newData) => {
   
    setDataSourceConfig((prevConfig) => ({ ...prevConfig, ...newData }));
  };
  const fetchAirtableConfig = async () => {

    try {
      const response = await fetch(
        "https://postsheetapp.com/api/accounts/get-airtable-config",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch Airtable configuration");
      }
      const configData = await response.json();
    

      if(!configData) {
        return;
      }
      const airtable_api_key =
        configData.airtable_config.api_key || configData.airtable_config.token;

      setAirtableApiKey(airtable_api_key);
      fetchAirtableBases(airtable_api_key);
      return configData; // Supondo que a chave retorne neste campo
    } catch (error) {
      console.error("Error fetching Airtable config:", error);
      recordError(error.message, "https://postsheetapp.com/api/accounts/get-airtable-config", null);
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };
  const fetchAirtableBases = async (airtableApiKey) => {
    try {


        const response = await fetch(
            "https://postsheetapp.com/api/accounts/get-airtable-bases",
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-Key": airtableApiKey,
                },
            }
        );

        if (!response.ok) {
            throw new Error(
                `Failed to fetch Airtable bases: ${response.statusText}`
            );
        }

        const basesData = await response.json();

        setAirtableBases(basesData.bases);
        // Log para verificação
        // Aqui você pode fazer algo com as bases, como armazená-las no estado
        // setAirtableBases(basesData.bases); // Supondo que você tenha um useState para isso
    } catch (error) {
        console.error("Error fetching Airtable bases:", error);
        alert("There was an error fetching your Airtable bases. Check your API Key at your account details.");
    }
}
  useEffect(() => {
    const getUserData = async () => {
    
      try {
        const response = await fetch("https://postsheetapp.com/api/user", {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch user: ${response.statusText}`);
        }

        const userData = await response.json();
      
        setUser(userData.user);
       
      } catch (error) {
        console.error("Error fetching user:", error);
        recordError(error.message, "https://postsheetapp.com/api/user", null);
        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };

    getUserData();
    
  }, []);

  // Função para avançar para o próximo painel
  const handleDataSourceSelect = (dataSourceKey, key, dataSourceAccountIdParam) => {
  
    if(key == 'csv' || key == 'xls') {
      setDataSourceType(key)
    } else {
  
      setDataSourceAccountId(dataSourceAccountIdParam)
      setDataSourceType(dataSourceKey);
    }
    setSelectedDataSource(key);
   
    setCurrentStep(currentStep + 1);
    updateActionConfig({ dataSourceKey: dataSourceKey });
  };

  // Chamado quando uma ação é selecionada em ActionSelector
  const handleActionSelect = (actionKey, accountId) => {
    setSelectedAction(actionKey);
    setActionAccountId(accountId)
    setActionType(actionKey)
    setCurrentStep(currentStep + 1);
    updateActionConfig({ actionKey: actionKey });
  };

  // Funções para navegar entre as etapas
  const goToNextStep = () => {
    setIsToNextStep(true)
    if (currentStep === 2) {
      if (isDataFetched) {
        setLoading(false);
        setAnimate(true);
        setCurrentStep(currentStep + 1);
      } else {
        setLoading(true);
      }
    } else {
      setAnimate(true);
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    }
  };
  const goToPreviousStep = () => {
    setAnimate(false)
    setIsToNextStep(true)
    if (currentStep > 0 && currentStep != 1) {
      setCurrentStep(currentStep - 1);
    }
    if (currentStep == 1) {
      setSelectedAction("")
      setSelectedDataSource("")
      setActionConfig({});
      setDataSourceConfig({})
      setActionAccountId("")
      setDataSourceColumns([])
      setJobActionConfig({})
      setJobItemRows([])
      setActionType("")
      setDataSourceType("")
      setEmailDataInternal({
        fromAddress: user.email,
        fromName: "",
        toAddress: "",
        toName: "",
        subject: "",
        emailTemplate: "", // Estado inicial para o conteúdo do CKEditor
      });
      setCurrentStep(currentStep - 1);
    }
    
  };
  const cardStyle = {
    padding: "1.5rem", // p-6 em Tailwind
    display: "flex",
    flexDirection: "column",
    maxWidth: "17rem",
    alignItems: "center",
    borderRadius: "0.5rem", // rounded-lg em Tailwind
    border: "2px solid #E5E7EB", // border-2 e border-gray-125 em Tailwind
    cursor: "pointer",
    transition: "border-color 0.3s",
  };

  const imgStyle = {
    maxWidth: "45px",
    maxHeight: "45px",
    width: "auto",
    height: "auto",
    objectFit: "contain",
    display: "block",
    margin: "0 auto",
  };
  let auth = useAuth();
  const fileInputRef = useRef(null);

  // Função para manipular o clique no botão e abrir o diálogo de seleção de arquivo
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleGoogleSheetUrlChange = async (url) => {
    
    setGoogleSheetUrl(url);
  
    const data = {
      sheets_url: url,
    };
  
    setIsDataFetched(false); // Start fetching
  
    try {
      const response = await fetch(
        "https://postsheetapp.com/api/google/get-gsheets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          credentials: "include",
          body: JSON.stringify(data),
        }
      );

      // Handle session expiration or token invalidation
      if (response.status === 401) {
        const responseData = await response.json();
        if (responseData.redirect) {
          alert('Your session has expired. You will be redirected to the login page for security reasons.');
          window.location.href = '/signin';  // Redirect to the login page
          return;
        }
      }
  
      if (!response.ok) {
        throw new Error("Failed to fetch data from Google Sheets");
      }
  
      const responseData = await response.json();
      const matches = url.match(/\/d\/([a-zA-Z0-9-_]+)\/edit(?:.*?gid=(\d+))?/);
      const spreadsheetId = matches ? matches[1] : null;
      const sheetId = matches && matches[2] ? matches[2] : null;

      updateDataSourceConfig({ spreadsheet_id: spreadsheetId, sheet_id: sheetId });
      updateActionConfig({
        gsheets_data: responseData.gsheets_data,
        columns: responseData.gsheets_data.columns,
        rows: responseData.gsheets_data.rows,
        row_count: responseData.gsheets_data.row_count,
      });

      setHighlightNextButton(true);
    } catch (error) {
      console.error("Error changing Google Sheets URL:", error);
      recordError(error.message, "https://postsheetapp.com/api/google/get-gsheets", data);
     
    } finally {
      setIsDataFetched(true); // End fetching
    }
  };

  // Carrega os dados das fontes de dados ao iniciar
  useEffect(() => {
    if (!auth.loading && auth.isAuthenticated && auth.id) {
  
      // Função para carregar os dados das fontes de dados

      const loadDataSources = async () => {
        let userId = auth.id;
        try {
          const response = await fetch("https://postsheetapp.com/jobs/new", {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "User-Id": userId,
              // Exemplo de como incluir um token de autenticação, se necessário
              // 'Authorization': 'Bearer YOUR_TOKEN_HERE',
            },
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
        
          if (
            data.user_data_source_choices.some(
              (item) => item.key === "airtable"
            )
          ) {
            fetchAirtableConfig();
            // Chama a função para buscar a configuração do Airtable
          }
          setUserDataSourceChoices(data.user_data_source_choices);
          setAvailableDataSourceChoices(data.available_data_source_choices);
          setUserActionTypes(data.user_action_types);
          setAvailableActionTypes(data.available_action_types);
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
          recordError(error.message, "https://postsheetapp.com/jobs/new", { "User-Id": userId });
          navigate('/app/error', { state: { errorMessage: error.message } });
        }
      };
      loadDataSources();
    }
  }, [auth.loading, auth.isAuthenticated, auth.id]); // O array de dependências vazio significa que isso será executado apenas uma vez, quando o componente for montado.
  const uploadFile = async (file) => {
    const url = "https://postsheetapp.com/api/user-files/upload";
    const formData = new FormData();
    formData.append("file", file);
  
  
    setIsDataFetched(false); // Start uploading
  
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": csrftoken,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
  
      updateActionConfig({
        s3_key: responseData.s3_key,
        columns: actionConfig.file_data.columns,
        rows: actionConfig.file_data.rows,
        row_count: actionConfig.file_data.row_count,
      });
      updateDataSourceConfig({s3_key: responseData.s3_key, size: file.size, name: file.name, content_type: file.type, user_id: user.id})
      setHighlightNextButton(true)
    } catch (error) {
      console.error("Upload error:", error);
      recordError(error.message, url, { file_name: file.name, file_size: file.size, content_type: file.type });
      navigate('/app/error', { state: { errorMessage: error.message } });
      throw error;
    } finally {
      setIsDataFetched(true); // End uploading
      
    }
  };
  const checkFileExists = async (file, onFileSelected) => {
    const url = "https://postsheetapp.com/api/user-files/check-file";
    const formData = new FormData();
    formData.append("file", file);
  
  
    setIsDataFetched(false); // Start checking
  
    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": csrftoken,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const result = await response.json();
  
      updateActionConfig({
        existing_file: result.exists,
        file_data: result.file_data,
        columns: result.file_data.columns,
        rows: result.file_data.rows,
        row_count: result.file_data.row_count,
      });
      updateDataSourceConfig({s3_key: result.s3_key, size: file.size, name: file.name, content_type: file.type, user_id: user.id})
      setHighlightNextButton(true)
      onFileSelected(file);
    } catch (error) {
      console.error("Error checking file:", error);
      recordError(error.message, url, { file_name: file.name, file_size: file.size, content_type: file.type });
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setIsDataFetched(true); // End checking
      setLoading(false);
    }
  };
  
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const handleSubmit = async (accountKey, inputData, accountId) => {
   

    const url = "https://postsheetapp.com/api/accounts/add";
    try {
        const formattedInputData = {};
        if (accountKey === 'twilio') {

            formattedInputData.account_sid = inputData[0] ? inputData[0] : inputData.account_sid;
            formattedInputData.auth_token = inputData[1] ? inputData[1] : inputData.auth_token;
        }
        if (accountKey === 'airtable') {
          if (accountId) {
            const keys = Object.keys(inputData);
        
            if (keys[0] === 'name') { 
           
              formattedInputData.token = inputData.token;
            } else {
           
              formattedInputData.token = inputData[0];
            }
            
          } else {
            formattedInputData.token = inputData.token ? inputData.token : inputData[0];
          }
          

      }  
        // Add other account types here if needed

        formattedInputData.name = inputData.name;
        if (accountId != null) {
          formattedInputData.account_id = accountId

        }
    
       
        const response = await fetch(url, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken,
            },
            body: JSON.stringify({
                type: accountKey,
                ...formattedInputData,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error("Error response:", errorData);
            throw new Error("Failed to add account");
        }

        const responseData = await response.json();
        navigate('/app/accounts');
   
    } catch (error) {
        console.error("Error during account addition:", error);
        recordError(error.message, url, { accountKey, inputData });
        navigate('/app/error', { state: { errorMessage: error.message } });
    }
};

const deleteAccount = async (accountId) => {
  const url = "https://postsheetapp.com/api/accounts/delete";
  
  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken, // Assuming you have a function to get the CSRF token
      },
      body: JSON.stringify({
        account_id: accountId
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Error response:", errorData);
      throw new Error("Failed to delete account");
    }

    const responseData = await response.json();

    
    // Redirect to the accounts page after successful deletion
    navigate('/app/accounts');

  } catch (error) {
    console.error("Error during account deletion:", error);
    recordError(error.message, url, { account_id: accountId });
    navigate('/app/error', { state: { errorMessage: error.message } });
  }
};
// useEffect(() => {
//   const fetchSubscriptionData = async () => {
//     console.log("Entrou em fetch Subscription Data")
//     try {
//       const url = 'https://postsheetapp.com/api/subscription-status'
//       const response = await fetch(url, {
//         method: 'GET',
//         credentials: 'include',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       console.log("Subscription Data", data)
//       setSubscriptionData(data);
     
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   fetchSubscriptionData()
// }, [subscriptionData]);
  const sendJobData = async () => {
    setLoadingBeforeRunJob(true);
    await new Promise((resolve) => setTimeout(resolve, 0)); 
    const jobSettings = {
      save_template: saveAsTemplate,
      stop_on_failed_items: stopOnFailedItems,
      detailed_warm_up: detailedInboxSpamWarmUp,
      warm_up_setting: warmUpSetting,
      track_emails: trackEmails,
      track_links: trackLinks
    };

    const jobData = {
      data_source_type: dataSourceType,
      data_source_config: dataSourceConfig,
      data_source_columns: dataSourceColumns,
      action_type: actionType,
      action_config: jobActionConfig,
      action_account_id: actionAccountId,
      job_item_rows: jobItemRows,
      scheduled_date_time: scheduledDate,
      campaign_name: jobName,
      job_settings: jobSettings,
      data_source_account_id: dataSourceAccountId,
      scheduled_date_time: scheduledDate,
      bcc_list: bccList,
    };
  
  
  
    const formData = new FormData();
    formData.append("data", JSON.stringify(jobData));
    if (attachment) {
    
      formData.append("attachment", attachment);
    }
  
  
  
    try {
      const response = await fetch('https://postsheetapp.com/api/jobs/create-job-job-items', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();

      const uid = result.job.uid;
      setLoadingBeforeRunJob(false)
      if(!scheduledDate) {
        
        navigate(`/app/details/${uid}`, { state: { totalItems: jobItemRows.length, job: result.job, enableRealTimeParam: true } });
      } else {
        navigate(`/app/details/${uid}`, { state: { totalItems: jobItemRows.length, job: result.job, enableRealTimeParam: false } });
      }
      
      if (!scheduledDate) {
        if (jobData.action_type == 'gmail.send-email' ) {
     
          await sendEmails(result.processed_emails, result.job.id, result.job.action_config.attached_file_s3_key);
        }
        else {
         
          await sendMessages(result.processed_emails, result.job.id)
        }
      }
      
      
    } catch (error) {
      console.error('Error creating job and job items or sending emails:', error);
      recordError(error.message, 'https://postsheetapp.com/api/jobs/create-job-job-items', jobData);
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setLoadingBeforeRunJob(false); 
    }
  };
  const sendEmails = async (emails, job_id) => {
    const batchSize = 5;
    let allBatchesSent = true;

    for (let i = 0; i < emails.length; i += batchSize) {
        const emailBatch = emails.slice(i, i + batchSize);
        const isFirstBatch = i === 0;
        const isLastBatch = (i + batchSize) >= emails.length;
        const formData = new FormData();
        formData.append('data', JSON.stringify({
            emails: emailBatch,
            job_id: job_id,
            is_first_batch: isFirstBatch,
            is_last_batch: isLastBatch
        }));
        if (attachment) {
            formData.append('attachment', attachment);
        }

        try {
            const response = await fetch('https://postsheetapp.com/api/emails/send', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to send emails');
            }

            const result = await response.json();

            if (result.error) {
                throw new Error(result.error);
            }
            await new Promise(resolve => setTimeout(resolve, 500));
        } catch (error) {
            console.error('Error sending emails:', error);
            recordError(error.message, 'https://postsheetapp.com/api/emails/send', { emails: emailBatch, job_id });
            allBatchesSent = false;
            break;  // Exit the loop on error
        }
    }

    if (allBatchesSent) {
        // Reset states only after all batches are sent successfully
        setCurrentStep(0);
        updateActionConfig({});
        setDataSourceColumns([]);
        setDataSourceConfig({});
        setActionAccountId("");
        setSelectedDataSource("");
        setSelectedAction("");
        setGoogleSheetUrl("")
        setActionType("");
        setActionConfig({});
        setJobActionConfig({});
        setSelectedBaseId('');
        setSelectedTableId('');
        setPreviewEmails([]);
        setScheduledDate(null);
        setSaveAsTemplate(false);
        setTrackEmails(false)
        setTrackLinks(false)
        setStopOnFailedItems(false);
        setJobName("");
        setIsDataFetched(false);
        setBccList([]);
        setAttachment(null);
        setDataSourceType("");
        setEmailData({
            fromAddress: user.email,
            fromName: "",
            toAddress: "",
            toName: "",
            subject: "",
            emailTemplate: "",
            bcc:"",
            unsubMessage: "If you'd like me to stop sending you emails, please",
            unsubLink: "click here"
        });
        setTwilioData({
            fromNumber: "",
            toNumber: "",
            unsubMessage: "If you'd like me to stop sending you sms, please",
            unsubLink: "click here"
        });
        setJobItemRows([]);
    }
};

const sendMessages = async (messages, job_id) => {

  const batchSize = 5; // Batch size set to 25
  let allBatchesSent = true;

  // Process the messages in batches
  for (let i = 0; i < messages.length; i += batchSize) {
    const messageBatch = messages.slice(i, i + batchSize);
    const isFirstBatch = i === 0;
    const isLastBatch = (i + batchSize) >= messages.length;
    const formData = new FormData();
    formData.append('data', JSON.stringify({
      messages: messageBatch, // Only the current batch of messages
      job_id: job_id,
      is_first_batch: isFirstBatch,
      is_last_batch: isLastBatch
    }));

    try {
      const response = await fetch('https://postsheetapp.com/api/messages/send', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to send messages');
      }

      const result = await response.json();
      await new Promise(resolve => setTimeout(resolve, 500));
      // If any error is present in the response, throw an error
      if (result.error) {
        throw new Error(result.error);
      }

    } catch (error) {
      console.error('Error sending messages:', error);
      recordError(error.message, `https://postsheetapp.com/api/messages/send`, { messages: messageBatch, job_id });
      allBatchesSent = false;
      break; // Stop processing batches if an error occurs
    }
  }

  // If all batches were sent successfully, reset the state
  if (allBatchesSent) {
    setCurrentStep(0);
    updateActionConfig({});
    setDataSourceColumns([]);
    setDataSourceConfig({});
    setActionAccountId("");
    setSelectedDataSource("");
    setSelectedAction("");
    setGoogleSheetUrl("")
    setActionType("");
    setActionConfig({});
    setJobActionConfig({});
    setSelectedBaseId('');
    setSelectedTableId('');
    setPreviewEmails([]);
    setScheduledDate(null);
    setSaveAsTemplate(false);
    setTrackEmails(false);
    setTrackLinks(false);
    setStopOnFailedItems(false);
    setJobName("");
    setIsDataFetched(false);
    setBccList([]);
    setAttachment(null);
    setDataSourceType("");
    setEmailData({
      fromAddress: user.email,
      fromName: "",
      toAddress: "",
      toName: "",
      subject: "",
      emailTemplate: "",
      bcc:"",
      unsubMessage: "If you'd like me to stop sending you emails, please",
      unsubLink: "click here"
    });
    setTwilioData({
      fromNumber: "",
      toNumber: "",
      unsubMessage: "If you'd like me to stop sending you sms, please",
      unsubLink: "click here"
    });
    setJobItemRows([]);
  }
};
  return (
    <NewCampaignContext.Provider
      value={{
        userDataSourceChoices,
        availableDataSourceChoices,
        currentStep,
        selectedDataSource,
        selectedAction,
        handleDataSourceSelect,
        handleActionSelect,
        goToNextStep,
        goToPreviousStep,
        userActionTypes,
        availableActionTypes,
        sendMessages,
        actionConfig,
        setDataSourceType,
        setSelectedDataSource,
        setActionConfig,
        handleGoogleSheetUrlChange,
        cardStyle,
        imgStyle,
        handleUploadClick,
        googleSheetUrl,
        uploadFile,
        checkFileExists,
        handleSubmit,
        airtableApiKey,
        airtableBases,
        updateActionConfig,
        user,
        editorRef,
        activeInputRef,
        handleInsertPlaceholder,
        emailData,
        setEmailData,
        handlePreview,
        previewEmails,
        setPreviewEmails,
        setActionConfig,
        updateDataSourceConfig,
        selectedTableId,
        setSelectedTableId,
        selectedBaseId,
        setSelectedBaseId,
        dataSourceConfig,
        setGoogleSheetUrl,
        sendMessages,
        animate,
        actionType,
        actionAccountId,
        dataSourceType,
        dataSourceColumns,
        jobActionConfig,
        jobItemRows,
        scheduledDate,
        setScheduledDate,
        setJobName,
        sendJobData,
        warmUpSetting, 
        setWarmUpSetting,
        loading,
        setLoading,
        stopOnFailedItems,
        setStopOnFailedItems,
        saveAsTemplate,
        setSaveAsTemplate,
        detailedInboxSpamWarmUp,
        setDetailedInboxSpamWarmUp,
        attachment,
        setAttachment,
        bccList, setBccList,
        templateData,
        trackEmails,
        trackLinks,
        setTrackEmails,
        setTrackLinks,
        setTemplateData,
        setTemplateDataInternal,
        setDataSourceColumns,
        clearTemplateData,
        isTemplateInitialized, 
        setIsTemplateInitialized,
        handleInsertTemplatePlaceholder,
        isDataFetched, setIsDataFetched,
        templateItemRows,
        setCurrentStep,
        setTemplateItemRows,
        twilioData,
        setTwilioData,
        setHighlightNextButton,
        highlightNextButton,
        twilioTemplateData,
        setTwilioTemplateData,
        clearTwilioTemplateData,
        setTwilioTemplateDataInternal,
        deleteAccount,
        loadingBeforeRunJob,
        sendEmails,
        setDataSourceConfig,
        isToNextStep,
        setIsToNextStep,
        fetchAirtableConfig, setAirtableApiKey
      }}
    >
      {children}
    </NewCampaignContext.Provider>
  );
};
