import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; 
const UserFilesContext = createContext();


export const useUserFiles = () => useContext(UserFilesContext);


export const UserFilesProvider = ({ children }) => {
  const { id, uid } = useAuth();
  const [userFiles, setUserFiles] = useState([]); 
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [fileDetails, setFileDetails] = useState(null); // Estado adicional para os detalhes do arquivo

    const [error, setError] = useState(null);
    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

            break;
          }
        }
      }
      return cookieValue;
    }
    const csrftoken = getCookie("csrftoken");
    const recordError = async (errorMessage, apiUrl, requestBody) => {
      try {
        const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
        await fetch(errorRecordUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({
            error_message: errorMessage,
            api_url: apiUrl,
            request: requestBody,
          }),
        });
      } catch (err) {
        console.error('Failed to record the error:', err);
      }
    };
    const fetchData = async () => {
      try {

        const url = 'https://postsheetapp.com/api/user-files'
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
   
        setUserFiles(data);
      } catch (error) {
        setError(error);
        recordError(error.message, 'https://postsheetapp.com/api/user-files', null);
        navigate('/app/error', { state: { errorMessage: error.message } });
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {

      fetchData();
    }, []);
    const fetchFileDetails = async (fileId) => {
      try {
        const url = `https://postsheetapp.com/api/user-files/details/${fileId}`;
        const response = await fetch(url, {
          method: 'GET', // Certifique-se que o método e endpoint estão corretos conforme sua API
          credentials: 'include', // ou omita se não necessário
          headers: {
            'Content-Type': 'application/json',
            // Inclua cabeçalhos de autenticação se necessário, exemplo:
            // 'Authorization': `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const details = await response.json();
        setFileDetails(details)     
        return details; // Retorna os detalhes do arquivo
      } catch (error) {
        console.error("Error fetching file details:", error);
        recordError(error.message, `https://postsheetapp.com/api/user-files/details/{fileId}`, { fileId });
        navigate('/app/error', { state: { errorMessage: error.message } });
      // Ou maneje o erro de uma maneira que faça sentido para sua aplicação
      }
    };
  
    const uploadFile = async (file) => {
      const url = 'https://postsheetapp.com/api/user-files/upload';
      const formData = new FormData();
      formData.append('file', file);
      
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: formData, // Nota: Não defina 'Content-Type' no cabeçalho para 'FormData'
          // O navegador cuidará de definir o 'Content-Type' apropriado para 'multipart/form-data'
          // e incluirá o 'boundary' necessário automaticamente
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const responseData = await response.json();
        fetchData()
        // Atualizar o estado ou realizar outras ações necessárias após o upload bem-sucedido
      
      } catch (error) {
    
        recordError(error.message, url, { fileName: file.name, fileSize: file.size, fileType: file.type });

        navigate('/app/error', { state: { errorMessage: error.message } });
        
      }
    };
    const deleteFiles = async (fileIds) => {
   
      const url = 'https://postsheetapp.com/api/user-files/delete';
      try {
        const response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify({files_ids: fileIds}), // Envia os IDs como um array JSON no corpo da requisição
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const responseData = await response.json();
        
        fetchData()
        // Atualizar o estado ou realizar outras ações necessárias após a deleção bem-sucedida
      
      } catch (error) {
        console.error("Delete error:", error);
        recordError(error.message, url, { fileIds });

        navigate('/app/error', { state: { errorMessage: error.message } });
      }
    };
    
    return (
      <UserFilesContext.Provider value={{ uploadFile, userFiles, deleteFiles, fetchFileDetails, loading, error }}>
        {children}
      </UserFilesContext.Provider>
    );
  };
  