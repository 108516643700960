import { Chip, Stack, Grid } from '@mui/material';
import { useSheetsExtension } from '../SheetsExtensionContext';

const ColumnsChips = ({ columns, userEmail }) => {
  const { handleInsertPlaceholder } = useSheetsExtension();

  return (
    <Grid
      container
      spacing={1} // Spacing between grid items
      style={{ marginTop: '1.5rem', maxWidth: '260px' }}
    >
      {columns.map((column, index) => (
        <Grid
          item
          xs={6} // Each chip will take half the width of the container
          key={index}
        >
          <Chip
            label={column.name}
            onClick={() => handleInsertPlaceholder(column.name)}
            sx={{
              width: '100%', // Make the chip fill the available width
              height: '32px', // Consistent height for all chips
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ColumnsChips;