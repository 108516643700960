import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

const SelectedEmail = ({ selectedEmail }) => {
  // Função para converter classes ql-* em estilos inline
  const convertQuillClassesToInlineStyles = (bodyHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = bodyHtml;

    // Substituir classes específicas por estilos inline
    tempDiv.querySelectorAll(".ql-align-center").forEach((el) => {
      el.style.textAlign = "center";
      el.classList.remove("ql-align-center");
    });

    tempDiv.querySelectorAll('[class*="ql-size-"]').forEach((el) => {
      const sizeClass = [...el.classList].find((cls) => cls.startsWith("ql-size-"));
      if (sizeClass) {
        const sizeMap = {
          "ql-size-small": "0.75em",
          "ql-size-large": "1.5em",
          "ql-size-huge": "2.5em",
        };
        el.style.fontSize = sizeMap[sizeClass] || "inherit";
        el.classList.remove(sizeClass);
      }
    });

    return tempDiv.innerHTML;
  };

  // Sanitização básica do HTML
  const sanitizeBodyHtml = (bodyHtml) => {
    return bodyHtml
      .replace(/<br>\s*&nbsp;/g, "<br>")
      .replace(/<p>/g, "<div>")
      .replace(/<\/p>/g, "</div>");
  };

  // Aplicar transformação e sanitização
  const styledBodyHtml = convertQuillClassesToInlineStyles(selectedEmail.body_html);
  const sanitizedBodyHtml = sanitizeBodyHtml(styledBodyHtml);


  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography>
          <strong>From:</strong> {selectedEmail.from_name} &lt;{selectedEmail.from_address}&gt;
        </Typography>
        <Typography>
          <strong>To:</strong> {selectedEmail.to_name} &lt;{selectedEmail.to_address}&gt;
        </Typography>
        <Typography>
          <strong>Subject:</strong> {selectedEmail.subject}
        </Typography>
        <Typography
          sx={{
            marginTop: 2,
            overflowWrap: "break-word",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
          }}
          dangerouslySetInnerHTML={{ __html: sanitizedBodyHtml }}
        />
      </Box>
    </>
  );
};

export default SelectedEmail;
