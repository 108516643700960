import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserFiles } from "../../../contexts/UserFilesContext";
import { UserFilesProvider } from "../../../contexts/UserFilesContext";
import { NewCampaignProvider } from "../../../contexts/NewCampaignContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Paper,
  Link,
  Checkbox,
  Box,
  Grid,
} from "@mui/material";
import FileDetailsModal from "../user_files/fileDetailsModal";
import {
  PrimaryButton,
  SecondaryButton,
  SubSecondaryButton,
} from "../../../styled-components/Buttons";
import { H2 } from "../../../styled-components/Typography";
import { useState, useRef } from "react";
import { useNewCampaign } from "../../../contexts/NewCampaignContext";

const UserFilesModal = ({ onFileSelect, onClose }) => {
  const { userFiles, loading, fetchFileDetails, uploadFile, deleteFiles } =
    useUserFiles();
  const {updateActionConfig, setDataSourceConfig, setDataSourceColumns, setSelectedDataSource, setDataSourceType, setCurrentStep} = useNewCampaign();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileDetails, setSelectedFileDetails] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const navigate = useNavigate(); // Add navigate hook

  const handleDetailsClick = async (fileId) => {
    const details = await fetchFileDetails(fileId);
    setSelectedFileDetails(details);
    setIsDetailsOpen(true);
    setPage(0);
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleCheckboxChange = (fileId) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.includes(fileId)
        ? prevSelectedFiles.filter((id) => id !== fileId)
        : [...prevSelectedFiles, fileId]
    );
  };

  const handleDeleteSelectedFiles = async () => {
    if (selectedFiles.length > 0) {
      await deleteFiles(selectedFiles);
      setSelectedFiles([]);
    }
  };

  const handleStartJob = async (file) => {
    try {
      const fileDetails = await fetchFileDetails(file.id);

      // Determine the dataSourceType based on file extension
      let dataSourceType = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (dataSourceType === 'xlsx') {
        dataSourceType = 'xls';
      }

      setSelectedDataSource(dataSourceType);
      setDataSourceType(dataSourceType);
      setCurrentStep(2);

      const initialState = {
        currentStep: 2,
        dataSourceType,
        selectedDataSource: dataSourceType,
        actionConfig: {
          s3_key: file.s3_key,
          columns: fileDetails.columns,
          rows: fileDetails.rows,
          row_count: fileDetails.row_count,
        },
        dataSourceConfig: {
          s3_key: file.s3_key,
          size: file.size,
          name: file.name,
          content_type: file.content_type,
          user_id: file.user_id,
        },
      };

      setDataSourceConfig(initialState.dataSourceConfig);
      updateActionConfig(initialState.actionConfig);
      setDataSourceColumns(initialState.actionConfig.columns);
      navigate("/app/jobs/new?step=2", { state: initialState });
    } catch (error) {
      console.error("Error starting job:", error);
      recordError(error.message, 'fetchFileDetails', { fileId: file.id });
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  };
  return (

    <>
    <Paper sx={{ width: "100%", overflow: "hidden", padding: '1rem' }}>
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TableContainer component={Paper} style={{ padding: "1.5rem" }}>
            <H2>My Uploaded Files</H2>
            <Table
              aria-label="uploaded files table"
              style={{ marginTop: "1.5rem" }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Check</TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>File Type</TableCell>
                  <TableCell>Upload Date</TableCell>
                  <TableCell>Start Job</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  userFiles.map((file) => (
                    <TableRow key={file.id}>
                      <TableCell>
                        <Checkbox
                          className="file-checkbox"
                          checked={selectedFiles.includes(file.id)}
                          onChange={() => handleCheckboxChange(file.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          href={`/app/files/${file.id}`}
                          underline="none"
                          sx={{ fontWeight: "bold" }}
                        >
                          {file.name.slice(0, -4)}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {file.name.substring(file.name.lastIndexOf("."))}
                      </TableCell>
                      <TableCell>{file.created_at}</TableCell>
                      <TableCell>
                      <PrimaryButton
                            variant="contained"
                            size="small"
                            onClick={() => {
                              if (onFileSelect) {
                                onFileSelect(file); // Pass selected file to the parent
                              }
                              onClose(); // Close modal
                            }}
                          >
                            Select File
                          </PrimaryButton>
                      </TableCell>
                      <TableCell>
                        <SecondaryButton
                          variant="contained"
                          size="small"
                          className="details-button"
                          onClick={() => handleDetailsClick(file.id)}
                          data-file-id={file.id}
                        >
                          Details
                        </SecondaryButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <FileDetailsModal
          isOpen={isDetailsOpen}
          onClose={() => setIsDetailsOpen(false)}
          selectedFileDetails={selectedFileDetails}
          page={page}
          setPage={setPage}
        />
        <Grid item>
          <Box mt={2}>
            <Stack direction="row" spacing={2}>
              
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".xls,.xlsx,.csv"
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    uploadFile(file);
                  }
                }}
              />
              <PrimaryButton onClick={handleUploadClick}>
                Upload Files
              </PrimaryButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      </Box>
      </Paper>
    </>
  
  );
};

export default UserFilesModal;
