import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';// Default CKEditor build
import './editor.css';

import { useSheetsExtension } from "../SheetsExtensionContext";

const TextEditor = () => {
  const { editorRef, activeInputRef, emailData, setEmailData } = useSheetsExtension();
  const quillRef = useRef(null);
  const convertQuillClassesToInlineStyles = (htmlContent) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  // Substituir classes específicas por estilos inline
  tempDiv.querySelectorAll('.ql-align-center').forEach((el) => {
    el.style.textAlign = 'center';
    el.classList.remove('ql-align-center');
  });

  tempDiv.querySelectorAll('[class*="ql-size-"]').forEach((el) => {
    const sizeClass = [...el.classList].find((cls) => cls.startsWith('ql-size-'));
    if (sizeClass) {
      const sizeMap = {
        'ql-size-small': '0.75em',
        'ql-size-large': '1.5em',
        'ql-size-huge': '2.5em',
      };
      el.style.fontSize = sizeMap[sizeClass] || 'inherit';
      el.classList.remove(sizeClass);
    }
  });

  return tempDiv.innerHTML;
};
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editorRef.current = editor;
    }
  }, []);

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <ReactQuill
        ref={quillRef}
        value={emailData.emailTemplate}
        onChange={(content, delta, source, editor) => {
          // Update emailData.emailTemplate whenever the content changes
          setEmailData('emailTemplate', content);
        }}
        onFocus={() => {
          // Set the activeInputRef to the Quill editor instance on focus
          activeInputRef.current = quillRef.current.getEditor();
        }}
        onBlur={() => {
          // Clear the activeInputRef when the editor loses focus
          activeInputRef.current = null;
        }}
        modules={{
          toolbar: [
            [{ font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        }}
        formats={[
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'color',
          'background',
          'align',
        ]}
        placeholder="Start typing here..."
      />
    </div>
  );
};

export default TextEditor;
