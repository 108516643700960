import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { H3 } from '../../../../../styled-components/Typography';
import { useJobDetailsExtension } from '../../DetailsExtensionContext';
import { PrimaryButton } from '../../../../../styled-components/Buttons';

const TemplateDetails = ({ jobId }) => {
    const [actionConfig, setActionConfig] = useState({});
    const { getJobTemplateDetails } = useJobDetailsExtension();

    useEffect(() => {
        if (jobId) {
            const fetchData = async () => {
                const data = await getJobTemplateDetails(jobId);
                setActionConfig(data.action_config);
            };
            fetchData();
        }
    }, [jobId, getJobTemplateDetails]);

    const handleShowTemplateModal = () => {
      const htmlString = createTemplateHTML();
      // Send message to parent window
      window.parent.postMessage({ action: 'send_failed_modal', html: htmlString }, '*');
    };

    const createTemplateHTML = () => {
      // Styles adapted from previous scenario: Paper-like styling, H3 styling, etc.
      const paperStyle = `
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: 100%;
        font-feature-settings: normal;
        font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
        font-size: 15px;
        font-variation-settings: normal;
        tab-size: 4;
        border: 0 solid #d8d5d2;
        box-sizing: border-box;
        background-color: rgb(255, 255, 255);
        color: rgba(0, 0, 0, 0.87);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
                    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
                    rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        position: relative;
        transition: box-shadow 300ms cubic-bezier(0.4,0,0.2,1);
        border-radius: 4px;
        padding: 32px;
        margin: 16px;
      `;

      const titleStyle = `
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: 100%;
        font-feature-settings: normal;
        font-variation-settings: normal;
        tab-size: 4;
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.75rem;
        margin-top: 0.25rem;
        margin: 0;
      `;

      const containerStyle = `
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: 100%;
        font-feature-settings: normal;
        font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
        font-size: 15px;
        font-variation-settings: normal;
        tab-size: 4;
        border: 0 solid #d8d5d2;
        box-sizing: border-box;
        margin-top: 16px;
      `;

      const textStyle = `
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: 100%;
        font-feature-settings: normal;
        font-family: inherit;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0.5rem 0;
        color: rgba(0,0,0,0.87);
      `;

      // Safely encode the body_html to prevent broken attributes:
      // Since we are using dangerouslySetInnerHTML in React, here we just insert it as is.
      // Make sure actionConfig.body_html is safe or sanitized if necessary.
      const fromText = `From: ${actionConfig.from_name} &lt;${actionConfig.from_address}&gt;`;
      const toText = `To: ${actionConfig.to_name} &lt;${actionConfig.to_address}&gt;`;
      const subjectText = `Subject: ${actionConfig.subject || ''}`;
      const bodyHtml = actionConfig.body_html || '';

      // We also add a button similar to previous scenario
      const buttonStyle = `
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: 100%;
        font-feature-settings: normal;
        font-family: inherit;
        font-size: 0.875rem;
        line-height: 1.75rem;
        font-weight: 500;
        text-transform: uppercase;
        padding: 6px 16px;
        border-radius: 4px;
        background-color: #1976d2;
        color: #fff;
        border: none;
        cursor: pointer;
        margin-top: 16px;
      `;

      return `
        <div style="${paperStyle}">
          <h3 style="${titleStyle}">Template</h3>
          <div style="${containerStyle}">
            <p style="${textStyle}"><b>${fromText}</b></p>
            <p style="${textStyle}"><b>${toText}</b></p>
            <p style="${textStyle}"><b>${subjectText}</b></p>
            <div style="margin-top: 1rem; ${textStyle}">
              ${bodyHtml}
            </div>
          </div>
        </div>
      `;
    };

    return (
        <>
            <Box sx={{ p: 4, m: 2, boxShadow: 3, borderRadius: 1, backgroundColor: '#fff' }}>
                <H3>Template</H3>
                <Box sx={{ mt: 2 }}>
                    <PrimaryButton variant="contained" onClick={handleShowTemplateModal}>
                        View
                    </PrimaryButton>
                </Box>
            </Box>
        </>
    );
};

export default TemplateDetails;
