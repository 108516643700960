import React, { useState, useEffect, useCallback } from 'react';
import { TextField, MenuItem, Button, Typography, Box, Paper } from '@mui/material';
import { useNewCampaign } from '../../../../../contexts/NewCampaignContext';
import { H2, SubHeader } from '../../../../../styled-components/Typography';
import { useNavigate } from 'react-router-dom';
function AirtablePanel({ bases }) {
  const {
    airtableApiKey,
    updateActionConfig,
    updateDataSourceConfig,
    selectedBaseId,
    selectedTableId,
    setSelectedTableId,
    loading,
    setLoading,
    setSelectedBaseId,
    isDataFetched, setIsDataFetched
  } = useNewCampaign();
  const navigate = useNavigate()
  const [tables, setTables] = useState([]);
  const [views, setViews] = useState([]);
  const [airtableData, setAirtableData] = useState(null);
  
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = 'https://postsheetapp.com/api/error-record';
      await fetch(errorRecordUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    }catch (err) {
      console.error('Failed to record the error:', err);
      navigate('/app/error', { state: { errorMessage: err.message } });
    }
  };
  const fetchAirtableData = useCallback(async (baseId, tableId) => {
    if (!baseId || !tableId) {
      setAirtableData(null);
      return;
    }
    setIsDataFetched(false)
   
     // Start fetching
  
    try {
      const response = await fetch(`https://postsheetapp.com/api/accounts/get-airtable-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({
          base_id: baseId,
          table_name: tableId,
          api_key: airtableApiKey,
        })
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch airtable data: ${response.statusText}`);
      }
  
      const data = await response.json();
      setAirtableData(data);
      updateDataSourceConfig({ base_id: baseId, table_id: tableId });
      updateActionConfig({ airtableData: data.airtable_data, columns: data.airtable_data.columns, rows: data.airtable_data.rows, row_count: data.airtable_data.row_count });
    } catch (error) {
      console.error('Error fetching airtable data:', error);
      
      setAirtableData(null);
      recordError(error.message, 'https://postsheetapp.com/api/accounts/get-airtable-data', { base_id: baseId, table_name: tableId, api_key: airtableApiKey });
      navigate('/app/error', { state: { errorMessage: error.message } });
    } finally {
      setIsDataFetched(true); // End fetching
    
    }
  }, [airtableApiKey, updateDataSourceConfig, updateActionConfig]);
  const fetchTablesForBase = useCallback(async (baseId) => {
    if (!baseId) {
      setTables([]);
      return;
    }

    try {
      const response = await fetch(`https://postsheetapp.com/api/accounts/get-airtable-tables`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': airtableApiKey,
          'X-Base-Id': baseId
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch tables: ${response.statusText}`);
      }

      const data = await response.json();
      setTables(data.tables);
    } catch (error) {
      console.error('Error fetching tables:', error);
      setTables([]);
      recordError(error.message, 'https://postsheetapp.com/api/accounts/get-airtable-tables', { base_id: baseId });
      navigate('/app/error', { state: { errorMessage: error.message } });
    }
  }, [airtableApiKey]);

  

  const handleBaseChange = (event) => {
    const newBaseId = event.target.value;
    setSelectedBaseId(newBaseId);
    fetchTablesForBase(newBaseId);
  };

  const handleTableChange = (event) => {
    const newTableId = event.target.value;
    setSelectedTableId(newTableId);

  };

  useEffect(() => {
    if (selectedBaseId && selectedTableId) {
      fetchAirtableData(selectedBaseId, selectedTableId);
    }
  }, [selectedBaseId, selectedTableId, fetchAirtableData]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Paper elevation={3} sx={{ p: 4, m: 2 }}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <H2>New Campaign</H2>
        <SubHeader>Which Airtable base, table, and view to use?</SubHeader>

        <TextField
          select
          label="Base"
          value={selectedBaseId}
          onChange={handleBaseChange}
          fullWidth
          sx={{ mt: 3 }}
        >
          {bases.map((base) => (
            <MenuItem key={base.id} value={base.id}>
              {base.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Table"
          value={selectedTableId}
          onChange={handleTableChange}
          fullWidth
          disabled={tables.length === 0}
          sx={{ mt: 3 }}
        >
          {tables.map((table) => (
            <MenuItem key={table.id} value={table.id}>
              {table.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Paper>
  );
}

export default AirtablePanel;
