import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
import { ProgressBar } from "../../../../../styled-components/ProgressBar";
import { SubHeader } from "../../../../../styled-components/Typography";
import { useJobDetailsExtension } from "../../DetailsExtensionContext";
import { PrimaryButton, SecondaryButton } from "../../../../../styled-components/Buttons";
import { useNavigate } from "react-router-dom";

const EmailProgressBar = ({
  totalItemsCount,
  statsSucceeded,
  statsFailed,
  jobId,
  actionType,
}) => {
  const navigate = useNavigate();
  const { enableRealTime, jobState } = useJobDetailsExtension();
  const [failedEmails, setFailedEmails] = useState([]);
  const [succeededItems, setSucceededItems] = useState(statsSucceeded);
  const [failedItems, setFailedItems] = useState(statsFailed);
  const [progress, setProgress] = useState(0);
  const [intervalDelay, setIntervalDelay] = useState(500);
  const [retryCount, setRetryCount] = useState(0);
  const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(5);

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrftoken = getCookie("csrftoken");

  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = "https://postsheetapp.com/api/error-record-extension";
      await fetch(errorRecordUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    } catch (err) {
      console.error("Failed to record the error:", err);
      navigate("/app/error", { state: { errorMessage: err.message } });
    }
  };

  useEffect(() => {
    let interval;
    const url = `https://postsheetapp.com/api/progress/${jobId}/`;

    const fetchProgress = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setSucceededItems(data.succeeded_items);
        setFailedItems(data.failed_items);
        setProgress(data.progress);
        setFailedEmails(data.failed_emails);

        if (data.progress >= 50) {
          setIntervalDelay(2000);
        }

        if (data.status !== "running" && data.status !== "initializing") {
          clearInterval(interval);
        }
      } catch (error) {
        recordError(error.message, url, null);
        setRetryCount((prev) => prev + 1);
        const backoffDelay = Math.min(1000 * 2 ** retryCount, 30000);
        setIntervalDelay(backoffDelay);
        navigate("/app/error", { state: { errorMessage: error.message } });
      }
    };

    if (jobState === "running" || jobState === "initializing") {
      interval = setInterval(fetchProgress, intervalDelay);
    } else {
      fetchProgress();
    }

    return () => clearInterval(interval);
  }, [intervalDelay, jobState, jobId, retryCount]);

 const createTableHTML = () => {
  const currentPageEmails = failedEmails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
  // Common styles for TH/TD:
  const thStyle = `
    border-bottom: 1px solid #ccc;
    text-align: left;
    padding: 8px;
    background: #f5f5f5;
  `;
  const tdStyle = `
    border-bottom: 1px solid #ccc;
    text-align: left;
    padding: 8px;
  `;

  let tableHead = "";
  let tableBody = "";

  if (actionType === "twilio.send-sms") {
    tableHead = `
      <tr>
        <th style="${thStyle}">To Number</th>
        <th style="${thStyle}">Twilio Error Code</th>
        <th style="${thStyle}">Error Details</th>
        <th style="${thStyle}">Twilio Error Details Url</th>
      </tr>
    `;
    tableBody = currentPageEmails.map(sms => `
      <tr>
        <td style="${tdStyle}">${sms.to_number}</td>
        <td style="${tdStyle}">${sms.error_code}</td>
        <td style="${tdStyle}">${sms.error_details}</td>
        <td style="${tdStyle}">
          ${sms.url_details ? `<a href="${sms.url_details}" target="_blank">${sms.url_details}</a>` : ""}
        </td>
      </tr>
    `).join("");
  } else {
    tableHead = `
      <tr>
        <th style="${thStyle}">Address</th>
        <th style="${thStyle}">Name</th>
        <th style="${thStyle}">Error</th>
      </tr>
    `;
    tableBody = currentPageEmails.map(email => `
      <tr>
        <td style="${tdStyle}">${email.to_address}</td>
        <td style="${tdStyle}">${email.to_name}</td>
        <td style="${tdStyle}">${email.error_message}</td>
      </tr>
    `).join("");
  }

  // Paper-like container styles
  const paperStyle = `
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 15px;
    font-variation-settings: normal;
    tab-size: 4;
    border: 0 solid #d8d5d2;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, 
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 4px;
    padding: 32px;
    margin: 16px;
  `;

  // Container for the table (with 1rem margin)
  const containerStyle = `
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 15px;
    font-variation-settings: normal;
    tab-size: 4;
    border: 0 solid #d8d5d2;
    box-sizing: border-box;
    margin-top: 32px;
    margin: 1rem; 
  `;

  // Title styles (h3)
  const titleStyle = `
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
    font-variation-settings: normal;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-top: 0.25rem;
    margin: 0;
  `;

  // Table styles
  const tableOuterStyle = `
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 15px;
    font-variation-settings: normal;
    tab-size: 4;
    border: 0 solid #d8d5d2;
    box-sizing: border-box;
    border-color: inherit;
    text-indent: 0;
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
  `;

  return `
    <div style="${paperStyle}">
      <h3 style="${titleStyle}">Failed Items</h3>
      <div style="${containerStyle}">
        <table style="${tableOuterStyle}">
          <thead>${tableHead}</thead>
          <tbody>${tableBody}</tbody>
        </table>
      </div>
    </div>
  `;
};

const handleShowFailedModal = () => {
  const htmlString = createTableHTML();
  window.parent.postMessage({ action: 'send_failed_modal', html: htmlString }, '*');
};


  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{succeededItems}</Typography>
          <Typography variant="subtitle1">Sent</Typography>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">{totalItemsCount}</Typography>
          <Typography variant="subtitle1">Total</Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <ProgressBar
              sx={{ width: "100%" }}
              variant="determinate"
              value={progress}
            />
          </Box>
          <div>{`${progress}%`}</div>
        </Box>
      </Box>

      {failedEmails.length > 0 && (
        <>
          <Box sx={{ textAlign: "center", marginTop: "1.5rem" }}>
            <Typography variant="h4">{failedEmails.length}</Typography>
            <Typography variant="subtitle1">Failed</Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <SubHeader>Failed Items</SubHeader>
            <SecondaryButton variant="contained" onClick={handleShowFailedModal}>
              View
            </SecondaryButton>
          </Box>
        </>
      )}
    </>
  );
};

export default EmailProgressBar;
