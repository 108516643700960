import React, { useState, useMemo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, TextField } from '@mui/material';
import { H3 } from '../../../styled-components/Typography';
import { schedulingService } from '../../user_only/new_job/Preview/services/SchedulingService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSheetsExtension } from './SheetsExtensionContext';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scheduler = ({ scheduledJobs }) => {
  const { setScheduledDate, scheduledDate } = useSheetsExtension();
  const [startDate, setStartDate] = useState(scheduledDate ? new Date(scheduledDate) : null);
  const [userTimezone, setUserTimezone] = useState('');

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setUserTimezone(timezone);
  }, []);

  const disabledIntervals = useMemo(() => {
    const intervals = schedulingService.getDisabledIntervals(scheduledJobs);

    return intervals;
  }, [scheduledJobs]);

  const handleDateChange = (date) => {
    if (!date) return;

    const localDateTime = dayjs(date);
    const utcDateTime = localDateTime.utc();
    const utcDate = utcDateTime.toDate();

    setScheduledDate(utcDate);
    setStartDate(date);
  };

  // const isDisabledDate = (date) => {
  //   // Simplified logic to disable specific dates
  //   const formattedDate = dayjs(date).format('YYYY-MM-DD');
  //   const isDisabled = disabledIntervals.some(interval => interval.date === formattedDate);
  //   console.log("Checking Date:", formattedDate, "Disabled:", isDisabled);
  //   return isDisabled;
  // };

  // const isDisabledTime = (time) => {
  //   // Simplified logic to disable specific times
  //   const formattedTime = dayjs(time).format('HH:mm');
  //   const isDisabled = disabledIntervals.some(interval => interval.time === formattedTime);
  //   console.log("Checking Time:", formattedTime, "Disabled:", isDisabled);
  //   return isDisabled;
  // };

  return (
    <Box sx={{ padding: 1, margin: 1, maxWidth: '100%' }}>
      <H3 sx={{ fontSize: '1.2rem', textAlign: 'center' }}>Schedule A Job</H3>
      <p style={{ fontSize: '0.9rem', textAlign: 'center' }}>
        Your Timezone: {userTimezone}
      </p>
      <Box sx={{ marginTop: 2 }}>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          showTimeSelect
          timeIntervals={15}
          minDate={new Date()}
          dateFormat="MMMM d, yyyy h:mm aa"
          customInput={<TextField fullWidth size="small" />}
          popperPlacement="bottom-start"
          popperModifiers={[
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Scheduler;
