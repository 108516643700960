import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { H3, SubHeader } from "../../../styled-components/Typography";
import { useNewCampaign } from "../../../contexts/NewCampaignContext";
import ColumnsChips from "./ColumnsChips";
import EmailForm from "./Form";
import TextEditor from "./Editor";
import TwilioTextEditor from "./TwilioEditor";
import TwilioForm from "../new_job/ActionConfig/Editor/TwilioForm";
import { useNavigate } from "react-router-dom";
import UploadDialog from "../new_job/dialogs/UploadDialog";
import UserFilesModal from "./UserFilesModal";

const CreateTemplate = () => {
  const {
    activeInputRef,
    setTemplateData,
    templateData,
    airtableBases,
    airtableApiKey,
    updateDataSourceConfig,
    updateActionConfig,
    clearTemplateData,
    isTemplateInitialized,
    twilioTemplateData,
    setIsTemplateInitialized,
    setTemplateDataInternal,
    templateItemRows,
    setTemplateItemRows,
    userDataSourceChoices,
    user,
    userActionTypes,
    attachment
  } = useNewCampaign();
  const navigate = useNavigate();
  const [template, setTemplate] = useState({
    name: "",
    action_type: "",
    data_source_type: "",
    data_source_config: {},
    data_source_columns: [],
    action_config: {},
    action_account_id: "",
    data_source_account_id: "",
    attachment_path: "",
  });

  const [sheetUrl, setSheetUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [templateHidden, setTemplateHidden] = useState(true);
  const [selectedBaseId, setSelectedBaseId] = useState("");
  const [selectedTableId, setSelectedTableId] = useState("");
  const [actionType, setActionType] = useState("");
  const [selectedDataSourceType, setSelectedDataSourceType] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [bases, setBases] = useState([]);
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState([]);
  const [includeOptOut, setIncludeOptOut] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);

  useEffect(() => {
    setTemplateData("fromAddress", user.email);
  }, [user.email, setTemplateData]);

  const handleSave = async () => {
    setConfirmDialogOpen(true);
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const handleFileSelection = (file) => {
    setSelectedFile(file);
    handleCloseModal();
    // Handle selected file logic here
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const csrftoken = getCookie("csrftoken");
  const recordError = async (errorMessage, apiUrl, requestBody) => {
    try {
      const errorRecordUrl = "https://postsheetapp.com/api/error-record";
      await fetch(errorRecordUrl, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          error_message: errorMessage,
          api_url: apiUrl,
          request: requestBody,
        }),
      });
    } catch (err) {
      console.error("Failed to record the error:", err);
      navigate("/app/error", { state: { errorMessage: err.message } });
    }
  };
  const handleConfirmSave = async () => {
  
    try {
      var payload;
      if (actionType == "gmail.send-email") {
        payload = {
          ...template,
          action_config: {
            ...template.action_config,
            from_address: user.email,
            from_name: templateData.fromName,
            to_address: templateData.toAddress,
            to_name: templateData.toName,
            subject: templateData.subject,
            body_html: templateData.emailTemplate,
            bcc: templateData.bcc,
            unsub_message: templateData.unsubMessage,
            unsub_link: templateData.unsubLink,
          },
          user_id: user.id,
          data_source_columns: columns,
          name: templateName,
        };
      } else {
        payload = {
          ...template,
          action_config: {
            ...template.action_config,
            from_number: twilioTemplateData.fromNumber,
            to_number: twilioTemplateData.toNumber,
            body_html: twilioTemplateData.smsTemplate,
            unsub_message: twilioTemplateData.unsubMessage,
            unsub_link: twilioTemplateData.unsubLink,
          },
          user_id: user.id,
          data_source_columns: columns,
          name: templateName,
        };
      }
      const formData = new FormData();
    formData.append("data", JSON.stringify(payload));

   

    if (attachment) {
      formData.append("attachment", attachment);
    }
      const response = await fetch(
        "https://postsheetapp.com/api/templates/create/",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "X-CSRFToken": csrftoken,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create template");
      }

      const data = await response.json();
      setConfirmDialogOpen(false);
      alert("Template created successfully!");
      navigate("/app/templates");
    } catch (error) {
      console.error("Error creating template:", error);
      alert("Error creating template");
      recordError(
        error.message,
        "https://postsheetapp.com/api/templates/create/",
        payload
      );

      navigate("/app/error", { state: { errorMessage: error.message } });
    }
  };

  const fileInputRef = useRef(null);

  const defaultUnsubMessage =
    "If you'd like me to stop sending you emails, please";
  const defaultUnsubLinkText = "click here";

  useEffect(() => {
    if (template.data_source_type === "airtable.airtable") {
      setBases(airtableBases);
      if (template.data_source_config) {
        setSelectedBaseId(template.data_source_config.base_id);
        setSelectedTableId(template.data_source_config.table_id);
        fetchTablesForBase(template.data_source_config.base_id);
      }
    } else if (template.data_source_type === "google.gsheets") {
      setSheetUrl(
        `https://docs.google.com/spreadsheets/d/${template.data_source_config.spreadsheet_id}/edit#gid=${template.data_source_config.sheet_id}`
      );
    } else if (
      template.data_source_type === "xls" ||
      template.data_source_type === "csv"
    ) {
      setFileName(template.data_source_config.name);
    }

    if (template.data_source_config) {
      setTemplateHidden(false);
      setColumns(template.data_source_columns);
    }
  }, [template.data_source_type, template.data_source_config, airtableBases]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
   
    setTemplateData(name, value); // Use context's setTemplateData
  };

  const handleDataSourceChange = (event) => {
    const newDataSourceType = event.target.value;

    setTemplate((prev) => ({
      ...prev,
      data_source_type: newDataSourceType.data_source_key,
      data_source_account_id: newDataSourceType.user_account_id,
      data_source_config: prev.data_source_config || {}, // Keep the existing data source config
    }));
    setSelectedDataSourceType(newDataSourceType.data_source_key);
    setSheetUrl("");
    setFileName("");
    setSelectedBaseId("");
    setSelectedTableId("");
    setTemplateHidden(true);
    setColumns([]);
  };
  const handleActionChange = (event) => {
    const newAction = event.target.value;
    setActionType(newAction.key);

    setTemplate((prev) => ({
      ...prev,
      action_type: newAction.key,
      action_account_id: newAction.account_id,
    }));
  };

  const handleUrlChange = async (event) => {
    setSheetUrl(event.target.value);
    const spreadsheetId = event.target.value.split("/d/")[1].split("/")[0];
    const sheetId = event.target.value.split("gid=")[1];
    setTemplate((prev) => ({
      ...prev,
      data_source_config: {
        spreadsheet_id: spreadsheetId,
        sheet_id: sheetId,
        url: event.target.value,
      },
    }));

    // Show the template and indicate that loading is in progress
    setTemplateHidden(false);
    setLoading(true);

    try {
      const response = await fetch(
        "https://postsheetapp.com/api/google/get-gsheets",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({ sheets_url: event.target.value }),
        }
      );

      const data = await response.json();

      if (response.status === 401 && data.redirect) {
        // If token is expired or revoked, show an alert and redirect to login page
        alert(
          "Your session has expired. You will be redirected to the login page for security reasons."
        );
        window.location.href = "/signin"; // Redirect to the login page
        return;
      }

      setColumns(data.gsheets_data.columns);
      setTemplateItemRows(data.gsheets_data.rows);
    } catch (error) {
      console.error("Failed to fetch Google Sheets data", error);
      recordError(
        error.message,
        "https://postsheetapp.com/api/google/get-gsheets",
        { sheets_url: event.target.value }
      );
      navigate("/app/error", { state: { errorMessage: error.message } });
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = (file) => {

    setSelectedFile(file);
    let fileConfig = {
      id: file.lastModified, // Temporary until actual ID is retrieved from backend
      name: file.name,
      size: file.size,
      s3_key: file.name, // Temporary until actual s3_key is retrieved from backend
      job_uid: "", // Empty by default
      user_id: user.id, // Assuming user.id is available in the context
      created_at: new Date().toISOString(),
      content_type: file.type,
    };
  
    setTemplate((prev) => ({
      ...prev,
      data_source_config: fileConfig,
    }));
  
    checkFileExists(file, (existingFile) => {
      if (existingFile) {
        fileConfig = {
          ...fileConfig,
          id: existingFile.id, // Use existing file ID
          s3_key: existingFile.s3_key, // Use existing s3_key
        };
  
        setTemplate((prev) => ({
          ...prev,
          data_source_config: fileConfig,
        }));
      }
  
      // Open upload dialog or perform additional actions if needed
      setIsModalOpen(false)
     
    });
  };

  const fetchTablesForBase = useCallback(
    async (baseId) => {
      if (!baseId) {
        setTables([]);
        return;
      }

      try {
        const response = await fetch(
          `https://postsheetapp.com/api/accounts/get-airtable-tables`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-API-Key": airtableApiKey,
              "X-Base-Id": baseId,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch tables: ${response.statusText}`);
        }

        const data = await response.json();
        setTables(data.tables);
      } catch (error) {
        console.error("Error fetching tables:", error);
        recordError(
          error.message,
          "https://postsheetapp.com/api/accounts/get-airtable-tables",
          { base_id: baseId }
        );
        navigate("/app/error", { state: { errorMessage: error.message } });
        setTables([]);
      }
    },
    [airtableApiKey]
  );

  const fetchAirtableData = useCallback(
    async (baseId, tableId) => {
      if (!baseId || !tableId) {
        return;
      }

      try {
        const response = await fetch(
          `https://postsheetapp.com/api/accounts/get-airtable-data`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrftoken,
            },
            body: JSON.stringify({
              base_id: baseId,
              table_name: tableId,
              api_key: airtableApiKey,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch airtable data: ${response.statusText}`
          );
        }

        const data = await response.json();
        handleColumnsComparison(data.airtable_data.columns);
        updateDataSourceConfig({ base_id: baseId, table_id: tableId });
        setTemplateItemRows(data.airtable_data.rows);
      } catch (error) {
        console.error("Error fetching airtable data:", error);
        recordError(
          error.message,
          "https://postsheetapp.com/api/accounts/get-airtable-data",
          { base_id: baseId, table_name: tableId, api_key: airtableApiKey }
        );
        navigate("/app/error", { state: { errorMessage: error.message } });
      }
    },
    [airtableApiKey, updateDataSourceConfig, updateActionConfig]
  );

  const handleBaseChange = (event) => {
    const newBaseId = event.target.value;
    setSelectedBaseId(newBaseId);
    fetchTablesForBase(newBaseId);
    setSelectedTableId("");
  };

  const handleTableChange = (event) => {
    const newTableId = event.target.value;
    setSelectedTableId(newTableId);
    setTemplate((prev) => ({
      ...prev,
      data_source_config: { base_id: selectedBaseId, table_id: newTableId },
    }));
    fetchAirtableData(selectedBaseId, newTableId);
  };

  const handleOptOutChange = (event) => {

    setIncludeOptOut(event.target.checked);
    if (event.target.checked) {
      setTemplateData((prev) => ({
        ...prev,
        unsubMessage: prev.unsubMessage || defaultUnsubMessage,
        unsubLink: prev.unsubLink || defaultUnsubLinkText,
      }));
    } else {
      setTemplateData((prev) => ({
        ...prev,
        unsubMessage: "",
        unsubLink: "",
      }));
    }

  };
  useEffect(() => {
    if (includeOptOut) {
      setTemplateData(
        "unsubMessage",
        templateData.unsubMessage || defaultUnsubMessage
      );
      setTemplateData(
        "unsubLink",
        templateData.unsubLink || defaultUnsubLinkText
      );
    }
  }, [includeOptOut]);
  const handleFocus = (event) => {

    activeInputRef.current = event.target;
  };

  const previewMessage = `${
    templateData.unsubMessage || defaultUnsubMessage
  } <a href="#">${templateData.unsubLink || defaultUnsubLinkText}</a>`;

  const handleColumnsComparison = (newColumns) => {
    const originalColumns = columns;

    const namesMatch = originalColumns.every(
      (col, index) => col.name === newColumns[index]?.name
    );
    const indexesMatch = originalColumns.every(
      (col, index) => col.index === newColumns[index]?.index
    );

    if (JSON.stringify(originalColumns) === JSON.stringify(newColumns)) {
      return;
    }

    if (namesMatch && newColumns.length > originalColumns.length) {
      setDialogMessage(
        "Your new data source has more columns than the last one, do you want to add those new columns to your options or want to keep the older data source?"
      );
      setDialogAction(() => {
        setTemplateHidden(false);
        setColumns(newColumns);
        setTemplate((prev) => ({
          ...prev,
          data_source_columns: newColumns,
        }));
        setDialogOpen(false);
      });
      setDialogOpen(true);
      return;
    }

    if (namesMatch && !indexesMatch) {
      setTemplateHidden(false);
      setColumns(newColumns);
      setTemplate((prev) => ({
        ...prev,
        data_source_columns: newColumns,
      }));
      return;
    }

    setDialogMessage(
      "The data source configs are different, do you want to reset your template? It will clear all the fields of your template."
    );

    setDialogAction(() => {
      clearTemplateData();
      setIsTemplateInitialized(false);
      setTemplateHidden(false);
      setColumns(newColumns);
      setTemplate((prev) => ({
        ...prev,
        data_source_columns: newColumns,
      }));
      setDialogOpen(false);
    });
    setDialogOpen(true);
  };

  const checkFileExists = async (file, onFileSelected) => {
 
    const url = `https://postsheetapp.com/api/user-files/details/${file.id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const details = await response.json();

     
        onFileSelected(file);
     
        setColumns(details.columns);
        setTemplate((prev) => ({
          ...prev,
          data_source_columns: details.columns,
        }));
        setTemplateItemRows(details.rows);
        updateDataSourceConfig({
          s3_key: file.s3_key,
          size: file.size,
          name: file.name,
          content_type: file.content_type,
          user_id: file.user_id,
          id: file.id
        });
     
    } catch (error) {
      console.error("Error checking file:", error);
      recordError(error.message, url, {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      });

      navigate("/app/error", { state: { errorMessage: error.message } });
    }
  };
  useEffect(() => {}, [actionType, template]);

  const fetchFileDetails = async (fileId) => {
    try {
      const url = `https://postsheetapp.com/api/user-files/details/${fileId}`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const details = await response.json();
      handleColumnsComparison(details.columns);
      updateDataSourceConfig({
        s3_key: details.s3_key,
        size: details.size,
        name: details.name,
        content_type: details.content_type,
        user_id: details.user_id,
      });
      setTemplateHidden(false);
      updateActionConfig({
        file_data: details,
        columns: details.columns,
        rows: details.rows,
        row_count: details.row_count,
      });
    } catch (error) {
      console.error("Error fetching file details:", error);
      recordError(
        error.message,
        `https://postsheetapp.com/api/user-files/details/{fileId}`,
        { fileId }
      );

      navigate("/app/error", { state: { errorMessage: error.message } });
    }
  };

  const uploadFile = async (file) => {
    const url = "https://postsheetapp.com/api/user-files/upload"; // Define the URL within the function

    // Create FormData instance with the file
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRFToken": csrftoken, // Assuming csrftoken is defined elsewhere in your code
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      // Update fileConfig with new ID and s3_key from the backend
      setTemplate((prev) => ({
        ...prev,
        data_source_config: {
          ...prev.data_source_config,
          id: responseData.id,
          s3_key: responseData.s3_key,
        },
      }));

      // Proceed with the next steps in your flow
      setTemplateHidden(false);
      fetchFileDetails(responseData.id);
    } catch (error) {
      console.error("Upload error:", error);
      recordError(error.message, url, {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      });

      navigate("/app/error", { state: { errorMessage: error.message } });
      throw error;
    }
  };

  const goToNextStep = () => {
    setUploadDialogOpen(false);
  };

  const isFormEnabled = template.data_source_type && columns.length > 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={3} sx={{ padding: "1rem" }}>
        <H3>Create Template</H3>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box fullWidth component="form" sx={{ mt: 2, width: "100%" }}>
            <TextField
              label="Template Name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
          </Box>
          <FormControl fullWidth sx={{ mt: 2, my: 2 }}>
            <InputLabel id="data-source-type-label">
              Data Source Type
            </InputLabel>
            <Select
              labelId="data-source-type-label"
              value={
                userDataSourceChoices.find(
                  (type) => type.data_source_key === template.data_source_type
                ) || ""
              }
              label="Data Source Type"
              onChange={handleDataSourceChange}
              renderValue={(selected) => (selected ? selected.title : "")}
            >
              {userDataSourceChoices.map((type) => (
                <MenuItem key={type.key} value={type}>
                  {type.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {template.data_source_type === "google.gsheets" && (
            <Box fullWidth component="form" sx={{ mt: 2, width: "100%" }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Your Google spreadsheet URL"
                value={sheetUrl || ""}
                onChange={handleUrlChange}
                required
                sx={{ mt: 2 }}
              />
            </Box>
          )}

          {(template.data_source_type === "xls" ||
            template.data_source_type === "csv") && (
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" component="label" onClick={handleOpenModal}>
                Choose File
               
              </Button>
              {fileName && (
                <Typography sx={{ mt: 2 }}>
                  Your File: <strong>{fileName}</strong>
                </Typography>
              )}
            </Box>
          )}

          {template.data_source_type === "airtable.airtable" && (
            <Box sx={{ mt: 2 }}>
              <SubHeader>Which Airtable base and table to use?</SubHeader>
              <TextField
                select
                label="Base"
                value={selectedBaseId || ""}
                onChange={handleBaseChange}
                fullWidth
                sx={{ mt: 3 }}
              >
                {airtableBases.map((base) => (
                  <MenuItem key={base.id} value={base.id}>
                    {base.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                label="Table"
                value={selectedTableId || ""}
                onChange={handleTableChange}
                fullWidth
                disabled={tables.length === 0}
                sx={{ mt: 3 }}
              >
                {tables.map((table) => (
                  <MenuItem key={table.id} value={table.id}>
                    {table.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
          {selectedDataSourceType != "" && (
            <FormControl fullWidth sx={{ mt: 2, my: 2 }}>
              <InputLabel id="data-source-type-label">Action Type</InputLabel>
              <Select
                labelId="data-source-type-label"
                value={
                  userActionTypes.find(
                    (type) => type.key === template.action_type
                  ) || ""
                }
                label="Data Source Type"
                onChange={handleActionChange}
                renderValue={(selected) =>
                  selected ? `${selected.name} - ${selected.title}` : ""
                }
              >
                {userActionTypes.map((type) => (
                  <MenuItem
                    key={type.key}
                    value={type}
                  >{`${type.name} - ${type.title}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {loading ? (
            <CircularProgress />
          ) : columns.length > 0 && actionType === "gmail.send-email" ? (
            <Box sx={{ my: 2 }}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Define your template
              </Typography>
              <ColumnsChips columns={columns} />
              <EmailForm columns={columns} />
              <TextEditor emailTemplate={templateData.emailTemplate} />
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeOptOut}
                      onChange={handleOptOutChange}
                    />
                  }
                  label="Include opt-out message"
                />
                {includeOptOut && (
                  <>
                    <TextField
                      label="Opt Out Message"
                      name="unsubMessage"
                      value={templateData.unsubMessage} // Controlled by templateData
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      sx={{ mt: 1 }}
                    />
                    <TextField
                      label="Opt Out Link Text"
                      name="unsubLink"
                      value={templateData.unsubLink} // Controlled by templateData
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      sx={{ mt: 1 }}
                    />
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">Preview:</Typography>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: previewMessage }}
                        sx={{
                          backgroundColor: "#f9f9f9",
                          p: 2,
                          borderRadius: 1,
                        }}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ) : columns.length > 0 && actionType === "twilio.send-sms" ? (
            <Box sx={{ my: 2 }}>
              <Typography variant="h6" sx={{ my: 2 }}>
                Define your template
              </Typography>
              <ColumnsChips columns={columns} />
              <TwilioForm columns={columns} />
              <TwilioTextEditor
                emailTemplate={twilioTemplateData.smsTemplate}
              />
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeOptOut}
                      onChange={handleOptOutChange}
                    />
                  }
                  label="Include opt-out message"
                />
                {includeOptOut && (
                  <>
                    <TextField
                      label="Opt Out Message"
                      name="unsubMessage"
                      value={twilioTemplateData.unsubMessage}
                      onFocus={handleFocus}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      sx={{ mt: 1 }}
                    />
                    <TextField
                      label="Opt Out Link Text"
                      name="unsubLink"
                      value={twilioTemplateData.unsubLink || ""}
                      onFocus={handleFocus}
                      fullWidth
                      inputProps={{ maxLength: 30 }}
                      sx={{ mt: 1 }}
                    />
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">Preview:</Typography>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: previewMessage }}
                        sx={{
                          backgroundColor: "#f9f9f9",
                          p: 2,
                          borderRadius: 1,
                        }}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ) : (
            <Typography
              variant="body2"
              sx={{
                my: 2,
                fontWeight: "bold",
                color: "darkgrey",
                backgroundColor: "rgba(173, 216, 230, 0.3)",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Set your spreadsheet data above (URL for gsheets, base and table
              for airtable or choose your .csv or .xls file) to display the
              template editor!
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            sx={{ my: 2 }}
            onClick={handleSave}
            disabled={!isFormEnabled}
          >
            Save
          </Button>
        </Box>
      </Paper>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Column Mismatch</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={dialogAction} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save the new template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="lg"
      >
        <UserFilesModal
          onFileSelect={handleFileSelect}
          onClose={handleCloseModal}
        />
      </Dialog>
      <UploadDialog
        openModal={uploadDialogOpen}
        setOpenModal={setUploadDialogOpen}
        uploadFile={uploadFile}
        goToNextStep={goToNextStep}
        selectedFile={selectedFile}
      />
    </Box>
  );
};

export default CreateTemplate;
